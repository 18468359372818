<template>
  <div class="bg-dark-4 list-group py-3" v-if="tracks?.length">
    <track-row
      v-for="(track, index) in tracks"
      :key="index"
      :track="track"
      :isAdmin="true"
    ></track-row>
  </div>
  <div v-else class="min-vh-50 d-flex align-items-center">
    <div class="w-100 text-center lead text-muted">
      Nothing here&hellip; Try uploading something!
    </div>
  </div>
  <div class="mt-4 text-center mb-4">
    <div class="btn-group">
      <router-link
        v-if="$route.query.page > 0"
        :to="{
          name: 'adminListTracksLocalSearch',
          query: {
            page: parseInt(this.$route.query.page || 0, 10) - 1,
            search: this.$route.query.search,
          },
        }"
        class="btn btn-light"
      >
        <span>Previous</span>
      </router-link>
      <router-link
        v-if="hasMore"
        :to="{
          name: 'adminListTracksLocalSearch',
          query: {
            page: parseInt(this.$route.query.page || 0, 10) + 1,
            search: this.$route.query.search,
          },
        }"
        class="btn btn-light"
      >
        <span>Next</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import adminTracksDb from "../../../services/adminTracksDb";
import TrackRow from "../../TrackRow.vue";

export default {
  name: "AdminListTracksLocalSearch",
  components: { TrackRow },
  data() {
    return {
      tracks: undefined,
      hasMore: false,
      search: this.$route.query.search,
    };
  },
  async beforeMount() {
    await this.loadTracks();
    this.$emit("initialized");
  },
  methods: {
    async loadTracks() {
      const page = this.$route.query.page || 0;
      const pageSize = this.$route.query.pageSize || 50;
      const search = this.$route.query.search;
      const method = search ? "search" : "getPage";

      const result = await adminTracksDb[method]({
        page,
        pageSize,
        search,
      });

      this.tracks = result.records;

      this.hasMore = result.hasMore;
    },
  },
};
</script>

<style scoped></style>
