<template>
  <div class="bg-waves d-flex align-items-center pb-1">
    <div class="container container-narrow container-narrow-lg mb-4">
      <div class="card p-4 text-start bg-dark-4 text-light">
        <div class="card-header text-secondary mb-0 text-end border-0">
          <h5 class="mb-0 scroll-m-top-4">Privacy Policy</h5>
          <p><date>6 April 2021</date></p>
        </div>
        <div class="card-body">
          <article>
            <div>
              <h2>Introduction</h2>
              <p>
                This Privacy Policy describes the type of information that we
                collect from you ("you/your") through the use of our services
                ("Services") or by providing services to us and how that
                information may be used or disclosed by us and the safeguards we
                use to protect it. We have drafted this Privacy Policy to be as
                clear and concise as possible. Please read it carefully to
                understand our policies regarding your information and how we
                will treat it. By using the Services or with us, providing
                services to us you agree to the collection, use and disclosure
                of information in accordance with this Privacy Policy. This
                Privacy Policy may change from time to time and your continued
                use of our Services or provision of services to us is deemed to
                be acceptance of such changes, so please check periodically for
                updates. You have the right to make a complaint at any time to
                the Information Commissioner's Office (ICO), the UK supervisory
                authority for data protection issues (www.ico.org.uk). We would,
                however, appreciate the chance to deal with your concerns before
                you approach the ICO so please contact us in the first instance.
                It is important that the personal data we hold about you is
                accurate and current. Please keep us informed if your personal
                data changes during your relationship with us. If you have any
                comments on this Privacy Policy, please email them to
                privacy@sound.ws.
              </p>
              <h2>2. Who We Are</h2>
              <ul class="list-unstyled">
                <li>
                  2.1 Here are our details:
                  <ul>
                    <li>Our Website address is https://www.mixthat.co</li>
                    <li>Our company name is First Coders Ltd</li>
                    <li>
                      Our registered address is 50 St. Marys Road, Hemel
                      Hempstead, England, HP2 5HL
                    </li>
                    <li>
                      Our nominated representative is Mark Cremer and he can be
                      contacted at mark@firstcoders.co.uk.
                    </li>
                  </ul>
                </li>
                <li>
                  2.2 We respect your right to privacy and will only process
                  personal information about you or provided by you in
                  accordance with the Data Protection Legislation which for the
                  purposes of this Privacy Policy shall mean: (i) the retained
                  EU law version of the General Data Protection Regulation ((EU)
                  2016/679) (UK GDPR), the Data Protection Act 2018 and any
                  other national implementing laws, regulations and secondary
                  legislation, as amended or updated from time to time, in the
                  UK and then (ii) any successor legislation to the UK GDPR or
                  the Data Protection Act 2018 and other applicable privacy
                  laws.
                </li>
              </ul>

              <h2>3. What we may collect</h2>
              <ul class="list-unstyled">
                <li>
                  3.1 Personal data, or personal information, means any
                  information about an individual from which that person can be
                  identified. It does not include data where the identity has
                  been removed (anonymous data).
                </li>
                <li>
                  3.2 We may collect, use, store and transfer different kinds of
                  personal data about you which we have grouped together as
                  follows:
                  <ul>
                    <li>
                      Identity Data includes first name, last name, username or
                      similar identifier. When you email, phone, or contact us
                      otherwise, we may collect information such as your first
                      name, last name, email address and phone number.
                    </li>
                    <li>
                      Contact Data includes billing address, invoicing address,
                      email address and telephone numbers.
                    </li>
                    <li>
                      Financial Data includes bank account and payment card
                      details.
                    </li>
                  </ul>
                </li>
                <li>
                  3.3 We do not collect any Special Categories of Personal Data
                  about you (this includes details about your race or ethnicity,
                  religious or philosophical beliefs, sex life, sexual
                  orientation, political opinions, trade union membership,
                  information about your health and genetic and biometric data).
                  Nor do we collect any information about criminal convictions
                  and offences.
                </li>
                <li>
                  3.4 Under UK GDPR we will ensure that your personal data is
                  processed lawfully, fairly, and transparently, without
                  adversely affecting your rights. We will only process your
                  personal data if at least one of the following basis applies:
                  <ul>
                    <li>
                      a) You have given consent to the processing of your
                      personal data for one or more specific purposes;
                    </li>
                    <li>
                      b) processing is necessary for the performance of a
                      contract to which you are a party or in order to take
                      steps at the request of you prior to entering into a
                      contract; or
                    </li>
                    <li>
                      c) processing is necessary for compliance with a legal
                      obligation to which we are subject; and/or d) processing
                      is necessary for the purposes of the legitimate interests
                      pursued by us or by a third party such as our financial
                      payments.
                    </li>
                  </ul>
                </li>
                <li>
                  3.5 If you provide personal information to us about another
                  data subject, you are responsible for ensuring that you have
                  their consent to provide that data for the uses set out in
                  this Privacy Policy and for bringing this Privacy Policy to
                  their attention.
                </li>
              </ul>
              <h2>4.How we may collect and use your data</h2>
              <ul class="list-unstyled">
                <li>
                  4.1 We (or third party data processors, agents and
                  sub-contractors acting on our behalf) may collect, store and
                  use your personal information by way of different methods to
                  collect data from and about you including through: Direct
                  interactions. You may give us your information by
                  corresponding with us by post, phone, email or otherwise. This
                  includes personal data you provide when you:
                  <ul>
                    <li>a) use any of our Services;</li>
                    <li>b) provide services to us; or</li>
                    <li>c) contact us via our website.</li>
                  </ul>
                </li>
                <li>
                  4.2 In addition to the above, we may use the information in
                  the following ways:
                  <ul>
                    <li>
                      a) provide information, and services that you request, or
                      (with your consent) which we think may interest you;
                    </li>
                    <li>b) carry out our contracts with you; or</li>
                    <li>c) provide the relevant Services to you.</li>
                  </ul>
                </li>
                <li>
                  4.3 If you are already our customer, we will only contact you
                  electronically about things similar to what was previously
                  sold to you.
                </li>
                <li>
                  4.4 If you are a new customer, you will only be contacted if
                  you agree to it.
                </li>
                <li>
                  4.5 In addition, if you don't want us to use your personal
                  data for any of the other reasons set out in this section in
                  4, you can let us know at any time by contacting us at
                  privacy@sound.ws, and we will delete your data from our
                  systems. However, you acknowledge this will limit our ability
                  to provide the best possible services to you.
                </li>
                <li>
                  4.6 We will only use your personal data when the law allows us
                  to. Most commonly, we will use your personal data in the
                  following circumstances:
                  <ul>
                    <li>
                      a) Where we need to perform the contract we are about to
                      enter into or have entered into with you.
                    </li>
                    <li>
                      b) Where it is necessary for our legitimate interests (or
                      those of a third party) and your interests and fundamental
                      rights do not override those interests.
                    </li>
                    <li>
                      c) Where we need to comply with a legal or regulatory
                      obligation, for example compliance with health and safety,
                      tax or other statutory obligations.
                    </li>
                  </ul>
                </li>
              </ul>
              <h2>5.Cookies</h2>
              <ul class="list-unstyled">
                <li>5.1 We do not use any cookies on our website.</li>
              </ul>
              <h2>6.Where we store your data and security</h2>
              <ul class="list-unstyled">
                <li>
                  6.1 We may transfer your collected data to storage outside the
                  UK. It may be processed outside the UK to receive our Services
                  and deal with payment. If we do store or transfer data outside
                  the UK, we will take all reasonable steps to ensure that your
                  data is treated as safely and securely as it would be within
                  the UK and under the UK GDPR. Such steps may include, but not
                  be limited to, the use of legally binding contractual terms
                  between us and any third parties we engage with and the use of
                  approved Model Contractual Arrangements. Your acceptance of
                  this Privacy Policy shall be your consent permitting us to
                  store or transfer data outside the UK if it is necessary for
                  us to do so.
                </li>
                <li>
                  6.2 Data security is of great importance to us, and to protect
                  your data we have put in place suitable physical, electronic
                  and managerial procedures to safeguard and secure personal
                  data that we hold. In addition, we limit access to your
                  personal data to those employees, agents, contractors and
                  other third parties who have a business need to know. They
                  will only process your personal data on our instructions and
                  they are subject to a duty of confidentiality.
                </li>
                <li>
                  6.3 We have put in place procedures to deal with any suspected
                  personal data breach and will notify you and any applicable
                  regulator of a breach where we are legally required to do so.
                </li>
                <li>
                  6.4 By giving us your personal data, you agree to this
                  arrangement. We will do what we reasonably can to keep your
                  data secure.
                </li>
                <li>
                  6.5 We have implemented security measures such as a firewall
                  to protect any data and maintain a high level of security.
                </li>
                <li>
                  6.6 Notwithstanding the security measures that we take, it is
                  important to remember that the transmission of data via the
                  internet may not be completely secure and that you are advised
                  to take suitable precautions when transmitting to us data via
                  the internet and you take the risk that any sending of that
                  data turns out to be not secure despite our efforts.
                </li>
                <li>
                  6.7 We will keep personal data for as long as is necessary
                  which is usually the life of our relationship and up to a
                  period of seven years after our relationship have ended. We
                  may however be required to retain personal data for a longer
                  period of time to ensure we comply with our legislative and
                  regulatory requirements. We review our data retention
                  obligations to ensure we are not retaining data for longer
                  than we are legally obliged to.
                </li>
              </ul>
              <h2>7.Disclosing your information</h2>
              <ul class="list-unstyled">
                <li>
                  We are allowed to disclose your information in the following
                  cases:
                  <ul>
                    <li>
                      7.1.1 If we want to sell our business, or our company, we
                      can disclose it to the potential buyer.
                    </li>
                    <li>
                      7.1.2 We can disclose it to other businesses in our group.
                    </li>
                    <li>
                      7.1.3 We can disclose it if we have a legal obligation to
                      do so, or in order to protect other people's property,
                      safety or rights.
                    </li>
                    <li>
                      7.1.4 We can exchange information with others to protect
                      against fraud or credit risks.
                    </li>
                  </ul>
                </li>
                <li>
                  7.2 We may contract with third parties to supply services to
                  you on our behalf such as sub-contractors. We will notify you
                  of this if it is relevant to our provision of Services to you.
                  In addition, we use certain third parties to store personal
                  data that we collect such as Fastmail and iCloud backup.
                </li>
                <li>
                  7.3 Where any of your data is required for such a purpose, we
                  will take all reasonable steps to ensure that your data will
                  be handled safely, securely, and in accordance with your
                  rights, our obligations, and the obligations of the third
                  party under UK GDPR and the law. Any third party that we share
                  data with will not be permitted to use it for any other
                  purpose than fulfilling their contract with us.
                </li>
              </ul>
              <h2>8.Your rights</h2>
              <ul class="list-unstyled">
                <li>
                  8.1 Under the UK GDPR, you have the right to:
                  <ul>
                    <li>
                      request access to, deletion of or correction of, your
                      personal data held by us at no cost to you;
                    </li>
                    <li>
                      request that your personal data be transferred to another
                      person (data portability);
                    </li>
                    <li>
                      be informed of what data processing is taking place;
                    </li>
                    <li>restrict processing;</li>
                    <li>to object to processing of your personal data; and</li>
                    <li>complain to a supervisory authority.</li>
                  </ul>
                </li>
                <li>
                  8.2 You have the right to ask us not to process your personal
                  data for marketing purposes. We will usually inform you
                  (before collecting your data) if we intend to use your data
                  for such purposes.
                </li>
                <li>
                  8.3 To enforce any of the foregoing rights or if you have any
                  other questions about this Privacy Policy, please contact us
                  at privacy@sound.ws.
                </li>
              </ul>
              <h2>9.Dispute Resolution</h2>
              <ul class="list-unstyled">
                <li>
                  9.1 We will use our best efforts to negotiate in good faith
                  and settle any dispute that may arise out of or relate to this
                  Privacy Policy or any breach of it.
                </li>
                <li>
                  9.2 Any dispute shall not affect our ongoing obligations under
                  this Privacy Policy.
                </li>
                <li>
                  9.3 This Privacy Policy and any dispute or claim relating to
                  or connected with it (including non-contractual disputes or
                  claims) shall be governed by and construed in accordance with
                  the laws of England and Wales and the courts of England and
                  Wales are the only place where disputes or claims relating to
                  or connected with this Privacy Policy (including
                  non-contractual disputes or claims) may be decided.
                </li>
              </ul>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',
};
</script>
