<template>
  <sidebar-layout :sidebarSize="4">
    <div>Coming soon</div>

    <!-- <article>
      <h1>Docs</h1>
      <h2>API</h2>
      <h3>Create a Track</h3>
      <h3>Modify a Track</h3>
      <h3>Delete a Track</h3>
      <h3>Create &amp; Download a Mix</h3>
      <h3>Get an access token</h3>

      <h2>Embed</h2>
      <h3>Embedding private tracks securely</h3>
      <h3>Styling the Stem Player</h3>
      <h3>Controlling the Stem Player</h3>
      <h3>Downloading a Mix</h3>

      <h2>Sharing</h2>
      <h3>Sharing a Track</h3>
      <h3>Sharing a Private Track</h3>
    </article> -->
    <template v-slot:sidebar
      ><div class="list-group mb-4">
        <div
          class="list-group-item list-group-item-secondary bg-secondary border-0 text-center text-light"
        >
          API
        </div>
        <a
          class="list-group-item list-group-item-action list-group-item-dark"
          aria-current="true"
        >
          Create a Track
        </a>
        <a class="list-group-item list-group-item-action list-group-item-dark"
          >Modify a Track</a
        >
        <a class="list-group-item list-group-item-action list-group-item-dark"
          >Delete a Track</a
        >
        <a class="list-group-item list-group-item-action list-group-item-dark"
          >Create &amp; Download a Mix</a
        >
        <a class="list-group-item list-group-item-action list-group-item-dark"
          >Get an access token</a
        >
      </div>

      <div class="list-group mb-4">
        <div
          class="list-group-item list-group-item-secondary bg-secondary border-0 text-center text-light"
        >
          Embed
        </div>
        <a
          class="list-group-item list-group-item-action list-group-item-dark"
          aria-current="true"
        >
          Embedding private tracks securely
        </a>
        <a class="list-group-item list-group-item-action list-group-item-dark"
          >Styling the Stem Player</a
        >
        <a class="list-group-item list-group-item-action list-group-item-dark"
          >Controlling the Stem Player</a
        >
        <a class="list-group-item list-group-item-action list-group-item-dark"
          >Downloading a mix</a
        >
      </div>

      <div class="list-group">
        <div
          class="list-group-item list-group-item-secondary bg-secondary border-0 text-center text-light"
        >
          Sharing
        </div>
        <a
          class="list-group-item list-group-item-action list-group-item-dark"
          aria-current="true"
        >
          Sharing a Track
        </a>
        <a
          class="list-group-item list-group-item-action list-group-item-dark"
          aria-current="true"
        >
          Sharing a Private Track
        </a>
      </div>
    </template>
  </sidebar-layout>
</template>

<script>
import SidebarLayout from "./SidebarLayout.vue";

export default {
  name: "Docs",
  components: { SidebarLayout },
};
</script>

<style scoped></style>
