import { reactive } from "vue";

class PlayerController {
  track = undefined;
  autoplay = undefined;

  async playMix({ trackuuid }, { autoplay, isAdmin, authToken }) {
    this.trackuuid = undefined;
    this.autoplay = autoplay;
    this.isAdmin = isAdmin;
    this.authToken = authToken;

    setTimeout(() => {
      this.trackuuid = trackuuid;
    }, 10);
  }

  unset() {
    this.trackuuid = undefined;
    this.autoplay = undefined;
    this.isAdmin = undefined;
    this.authToken = undefined;
  }
}

export default {
  install(app) {
    app.config.globalProperties.$player = reactive(new PlayerController());
    app.provide("player", app.config.globalProperties.$player);
  },
};
