<template>
  <modal v-slot="scope">
    <div class="min-vh-75 d-flex align-items-center py-1 text-center">
      <div class="w-100">
        <div class="bg-dark-4 list-group py-3 mb-4">
          <router-link
            v-if="isAuthenticated"
            :to="{ name: 'adminListTracks' }"
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item d-flex justify-content-between align-items-center"
            @click="scope.close()"
            >My Tracks</router-link
          >
          <router-link
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item d-flex justify-content-between align-items-center"
            :to="{ name: 'adminEditAccount' }"
            @click="scope.close()"
            v-if="isAuthenticated"
            >Account</router-link
          >
          <button
            v-if="isAuthenticated"
            @click="signOutClick(scope)"
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item d-flex justify-content-between align-items-center"
          >
            Sign Out
          </button>
          <button
            v-if="isAuthenticated === false"
            @click="signInClick(scope)"
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item d-flex justify-content-between align-items-center"
          >
            Sign In
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "./Modal.vue";

export default {
  name: "NavbarNavModal",
  components: { Modal },
  emits: ["signOutClick", "signInClick"],
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    signInClick(scope) {
      this.$emit("signInClick");
      scope.close();
    },
    signOutClick(scope) {
      this.$emit("signOutClick");
      scope.close();
    },
  },
};
</script>

<style scoped></style>
