<template>
  <div :class="styles" role="button">
    <slot></slot>

    <span class="badge bg-success rounded-pill d-top" v-if="state === 'success'"
      ><i class="bi bi-check"></i
    ></span>
    <span
      class="badge bg-success rounded-pill bg-danger d-top"
      v-if="state === 'error'"
      ><i class="bi bi-exclamation-triangle-fill"></i
    ></span>
    <span
      class="badge bg-secondary rounded-pill d-top"
      v-if="state === 'loading'"
      ><span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <span class="visually-hidden">Loading...</span></span
    >

    <div
      class="position-absolute start-0 top-0 bg-secondary h-100 opacity-25 pe-none user-select-none"
      :style="`width:${progress}%`"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ProgressListGroupItem",
  props: {
    progress: {
      type: Number,
      required: false,
      default: 0,
    },
    state: {
      type: String,
      required: false,
      default: undefined,
    },
    styles: {
      type: String,
      default:
        "list-group-item list-group-item-dark list-group-item-action text-truncate py-3 position-relative d-flex justify-content-between align-items-center",
    },
  },
};
</script>

<style scoped></style>
