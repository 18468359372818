<template>
  <download-modal
    v-if="openModalName === 'download'"
    @close="openModalName = undefined"
    :track="track"
    :isAdmin="isAdmin"
  ></download-modal>
  <embed-modal
    v-if="openModalName === 'embed'"
    @close="openModalName = undefined"
    :track="track"
    :isAdmin="isAdmin"
  ></embed-modal>
  <share-modal
    v-if="openModalName === 'share'"
    @close="openModalName = undefined"
    :track="track"
    :isAdmin="isAdmin"
  ></share-modal>
  <div class="btn-group mb-2">
    <button @click="download()" class="btn btn-light text-nowrap">
      Download
    </button>
    <button @click="share()" class="btn btn-light text-nowrap">Share</button>
    <button @click="embed()" class="btn btn-light text-nowrap">Embed</button>
  </div>
</template>

<script>
import EmbedModal from "../EmbedModal.vue";
import ShareModal from "../ShareModal.vue";
import DownloadModal from "../DownloadModal.vue";

export default {
  name: "ViewTrackActionButtons",
  components: {
    EmbedModal,
    ShareModal,
    DownloadModal,
  },
  data() {
    return {
      openModalName: undefined,
    };
  },
  props: {
    track: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    download() {
      this.openModalName = "download";
    },
    share() {
      this.openModalName = "share";
    },
    embed() {
      this.openModalName = "embed";
    },
  },
};
</script>

<style scoped></style>
