<template>
  <div class="d-inline-block position-relative">
    <a @mouseover="hover = true" @mouseleave="hover = false"
      ><i :class="iconClass"></i
    ></a>
    <div
      class="position-absolute top-0 bg-light text-dark w-tooltip p-2 ms-4 z-index-top rounded shadow-sm"
      :class="{ 'd-none': !hover }"
    >
      <small><slot></slot></small>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    iconClass: {
      type: String,
      default: "bi bi-question-circle-fill",
    },
  },
  data() {
    return { hover: false };
  },
};
</script>

<style scoped>
.w-tooltip {
  width: 250px;
}
</style>
