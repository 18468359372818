import { API, Cache } from "aws-amplify";
import adminGetUserProfile from "./adminGetUserProfile";
import adminGetAccount from "./adminGetAccount";

export default async (body) => {
  const paths = [
    `/user-profiles/${body.username}`,
    `/user-profiles/${body.cognitoIdentityId}`,
    "/admin/account",
  ];

  // remove the current user from the cache
  paths.forEach((p) => Cache.removeItem(p));

  // store data
  await API.put("admin", `/admin/account`, {
    body,
  });

  const account = await adminGetAccount();

  // refresh the cache entry for the current user in the cache so that later calls to getUserProfile will return fresh data
  const userProfile = await adminGetUserProfile(account.cognitoIdentityId);
  paths.forEach((p) => Cache.setItem(p, userProfile));
};
