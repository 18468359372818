<template>
  <div>
    <div>
      <div class="mb-3">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            aria-label="Search"
            @input="onSearchInput"
            ref="searchInput"
            v-model="search"
            placeholder="Start typing to search"
            aria-describedby="inputGroup-sizing-default"
          />
          <button
            v-if="usesLocalSearch"
            class="btn btn-light"
            type="button"
            id="button-addon2"
            @click="refresh"
            :disabled="isRefreshing"
          >
            <i
              class="bi bi-arrow-clockwise d-inline-block"
              :class="{ spin: isRefreshing }"
            ></i>
          </button>
        </div>
      </div>
    </div>
    <router-view v-if="!isRefreshing"></router-view>
    <div v-else class="min-vh-50 d-flex align-items-center">
      <div class="w-100">
        <loader></loader>
      </div>
    </div>
  </div>
</template>

<script>
import adminTracksDb from '../../services/adminTracksDb';
import adminGetAccount from '@/services/adminGetAccount';
import Loader from '../Loader.vue';
import { maxLocalTracksSearch } from '../../../config';

export default {
  name: 'AdminListTracksSearch',
  components: { Loader },
  data() {
    return {
      tracks: undefined,
      hasMore: false,
      isRefreshing: false,
      search: this.$route.query.search,
      usesLocalSearch: false,
    };
  },
  async mounted() {
    this.$refs.searchInput?.focus();

    const { nTracks, enableOnlineSearch } = await adminGetAccount({
      cache: true,
    });

    if (!enableOnlineSearch && (nTracks || 0) < maxLocalTracksSearch) {
      this.usesLocalSearch = true;
      const count = await adminTracksDb.getCount();
      if (count === 0) this.refresh();
    }

    if (this.$route.name === 'adminListTracks')
      this.$router.replace({
        name: this.usesLocalSearch
          ? 'adminListTracksLocalSearch'
          : 'adminListTracksOnlineSearch',
        query: { search: this.$route.query.search },
      });
  },
  methods: {
    async refresh() {
      try {
        this.isRefreshing = true;
        await adminTracksDb.refresh();
      } finally {
        this.isRefreshing = false;
      }
    },
    async onSearchInput(event) {
      this.$router.replace({
        name: this.usesLocalSearch
          ? 'adminListTracksLocalSearch'
          : 'adminListTracksOnlineSearch',
        query: { search: event.target.value ? event.target.value : undefined },
      });
    },
  },
};
</script>

<style scoped>
.card {
  min-height: 300px;
}
</style>
