import { Auth, Cache } from "aws-amplify";

const key = "_currentUserCredentials";

export default async () => {
  const cached = Cache.getItem(key);
  if (cached) return cached;

  const creds = await Auth.currentUserCredentials();

  // store in cache
  const expiration = new Date(creds.expiration);

  Cache.setItem(key, creds, {
    expires: expiration.getTime(),
  });

  return creds;
};
