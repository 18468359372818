<template>
  <iframe
    class="preview"
    :src="`/embed-preview.html?url=${streamUrl}&controls=${controls}&collapsed=${
      collapsed ? 'collapsed' : ''
    }&maxHeight=${maxHeight}`"
  ></iframe>
</template>

<script>
export default {
  name: 'EmbedPreview',
  props: {
    streamUrl: {
      type: String,
      required: true,
    },
    controls: {
      type: String,
      required: true,
      default: '',
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxHeight: {
      type: String,
      default: '350px',
    },
  },
};
</script>

<style scoped>
iframe.preview {
  width: 100%;
  border: 0;
  background-color: black;
}
</style>
