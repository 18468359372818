<template>
  <div v-if="isLoading">
    <loader></loader>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-6">
        <div class="card bg-dark-4 mb-4 position-relative">
          <div class="card-header text-secondary text-center">Streams</div>
          <div class="card-body py-0">
            <Bar
              :data="chartDataPlayMix"
              :options="chartOptions"
              :height="250"
            ></Bar>
          </div>
          <div
            v-if="!chartDataPlayMix.datasets.length"
            class="position-absolute text-muted w-100 h-100 text-center d-flex align-items-center"
          >
            <p class="w-100">No data for this period</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-4">
        <div class="card bg-dark-4 position-relative">
          <div class="card-header text-secondary text-center">Downloads</div>
          <div class="card-body py-0">
            <Bar
              :data="chartDataDownloadMix"
              :options="chartOptions"
              :height="250"
            ></Bar>
          </div>
          <div
            v-if="!chartDataDownloadMix.datasets.length"
            class="position-absolute text-muted w-100 h-100 text-center d-flex align-items-center"
          >
            <p class="w-100">No data for this period</p>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-4"></div>
    <div class="mb-4"></div>
  </div>
</template>

<script>
import Loader from "./Loader.vue";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import adminGetTrackstats from "../services/adminGetTrackstats";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default {
  name: "TrackStats",
  components: { Loader, Bar },
  props: {
    trackuuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: undefined,
      isLoading: false,
      chartDataPlayMix: {
        labels: months,
        datasets: [],
      },
      chartDataDownloadMix: {
        labels: months,
        datasets: [],
      },
      chartOptions: {
        // scales: {
        //   yAxes: [
        //     {
        //       ticks: {
        //         beginAtZero: true,
        //       },
        //     },
        //   ],
        // },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            align: "end",
            position: "bottom",
          },
        },

        // title: {
        //   display: true,
        //   text: "Monthly Income",
        // },
      },
    };
  },
  async beforeMount() {
    try {
      this.isLoading = true;
      const response = await adminGetTrackstats(this.trackuuid);
      const records = response.data;

      const last2Years = records
        .sort((a, b) => (a.year < b.year ? 1 : -1)) // sort newest first
        .slice(0, 2); // limit 2

      if (last2Years.length) {
        const totalPlayMixes = last2Years
          .map((year) => year.events.PLAY_MIX.total)
          .reduce((a, x) => a + x);

        if (totalPlayMixes > 0) {
          this.chartDataPlayMix.datasets = last2Years.map((r, index) => ({
            label: r.year,
            data: r.events.PLAY_MIX?.split,
            backgroundColor: ["rgb(255, 196, 0)", "rgb(1, 164, 179)", "white"][
              index
            ],
          }));
        }

        const totalDownloads = last2Years
          .map((year) => year.events.DOWNLOAD_MIX.total)
          .reduce((a, x) => a + x);

        if (totalDownloads > 0) {
          this.chartDataDownloadMix.datasets = last2Years.map((r, index) => ({
            label: r.year,
            data: r.events.DOWNLOAD_MIX?.split,
            backgroundColor: ["rgb(255, 196, 0)", "rgb(1, 164, 179)", "white"][
              index
            ],
          }));
        }
      }

      // this.chartData.datasets[0].label = thisYear.year;
      // this.chartData.datasets[0].data = [1, 2, 3];
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
