<template>
  <div>
    <label for="formFileLg" class="form-label">{{ title }}</label>
    <tooltip class="ms-2" v-if="tooltip">{{ tooltip }}</tooltip>
    <div>
      <div v-if="files && filesAndUploads.length" class="mb-2">
        <!-- <label for="formFileLg" class="form-label text-muted mb-1"
          ><i class="bi bi-paperclip"></i> Files</label
        > -->
        <div class="list-group text-start">
          <progress-list-group-item
            v-for="(file, index) in filesAndUploads"
            :key="index"
            class="list-group-item-dark d-flex"
            :progress="file.progress"
          >
            <div v-if="filesAndUploads.length > 1">
              <button
                class="btn btn-dark my--2 ms--2 me-2"
                v-if="index === 0"
                @click.prevent="down(file, index)"
              >
                <i class="bi bi-arrow-down-circle-fill"></i>
              </button>
              <button
                class="btn btn-dark my--2 ms--2 me-2"
                v-else
                @click.prevent="up(file, index)"
              >
                <i class="bi bi-arrow-up-circle-fill"></i>
              </button>
            </div>
            <input
              v-if="hasLabel"
              type="text"
              class="form-control bg-transparent text-light border-0 position-relative shadow-none p-0 me-2"
              v-model="file.label"
              ref="input"
              :placeholder="file.filename"
            />
            <input
              v-else
              type="text"
              class="form-control bg-transparent text-light border-0 position-relative shadow-none p-0 me-2"
              :value="file.filename"
              ref="input"
              disabled
            />
            <button
              class="btn btn-danger my--2 me--2"
              @click.prevent="removeFile(file, index)"
            >
              <i class="bi bi-x"></i>
            </button>
          </progress-list-group-item>
        </div>
      </div>
      <div
        class="form-group"
        :class="{ 'd-none': !multiple && filesAndUploads.length === 1 }"
      >
        <div>
          <input
            class="form-control"
            :accept="accept"
            type="file"
            :multiple="multiple"
            ref="fileSelect"
            @change="onChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uploadFile from '../services/uploadFile';
import { v4 as createUuid } from 'uuid';
import { reactive } from 'vue';
import { maxUploadFileSize } from '../../config';
import Tooltip from './Tooltip.vue';
import ProgressListGroupItem from './ProgressListGroupItem.vue';

export default {
  name: 'FileFormField',
  components: { Tooltip, ProgressListGroupItem },
  props: {
    title: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 999,
    },
    accept: {
      type: String,
      default: '*',
    },
    tooltip: {
      type: String,
      require: false,
    },
    hasLabel: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'preupdate'],
  data() {
    return { uploads: [], errors: [] };
  },
  computed: {
    filesAndUploads() {
      let r = [];

      if (Array.isArray(this.modelValue)) {
        r = [...this.modelValue, ...this.uploads];
      } else if (this.uploads.length) {
        r = this.uploads;
      } else if (this.modelValue) r = [this.modelValue];

      return r.sort((a, b) => (a.index < b.index ? -1 : 1));
    },
    files() {
      if (Array.isArray(this.modelValue)) return this.modelValue;
      else if (this.modelValue) return [this.modelValue];
      return [];
    },
  },
  mounted() {
    this.validate();
  },
  methods: {
    async onChange(e) {
      const { files } = e.target;
      const isValid = this.validateSelectedFiles(files);

      if (isValid) {
        this.uploadFilelist(files);
        // do not await,.. trigger validation state of files still uploading
        this.validate();
      }
    },

    validateSelectedFiles(files) {
      const field = this.$refs.fileSelect;
      const hasFilesThatAreTooBig = !!Array.from(files).find(
        (file) => file.size > maxUploadFileSize,
      );

      if (hasFilesThatAreTooBig) {
        field.setCustomValidity(
          `Please select files that are not larger than ${
            maxUploadFileSize / 1024 / 1024
          } MB`,
        );

        field.reportValidity();
      }

      return !hasFilesThatAreTooBig;
    },

    validate() {
      const filesStillBeingUploaded = this.uploads.filter(
        (file) => file.status === 'IN_PROGRESS',
      );
      const field = this.$refs.fileSelect;

      if (this.filesAndUploads.length < parseInt(this.min, 10)) {
        field.setCustomValidity(
          `A minimum of ${this.min} files must be selected`,
        );
      } else if (this.filesAndUploads.length > parseInt(this.max, 10)) {
        field.setCustomValidity(
          `A maximum of ${this.max} files can be selected`,
        );
      } else if (filesStillBeingUploaded.length) {
        field.setCustomValidity('Some files are still uploading. Please wait.');
      } else {
        field.setCustomValidity('');
      }

      // field.reportValidity();
    },

    async uploadFilelist(filelist) {
      const uploads = Array.from(filelist).map((file) => this.uploadFile(file));

      // unselect fileselect
      this.$refs.fileSelect.value = '';

      // if multiple select is true, combine the model files with the newly uploaded ones.
      // if multiple select is false, replace the modelvalue with the newly uploaded one.
      const getFiles = () =>
        this.multiple ? [...this.files, ...uploads] : uploads[0];

      // if preUpdate sets a promise, because some async work needs to complete... wait for it
      const payload = {
        data: getFiles(),
      };

      this.$emit('preupdate', payload);

      if (payload.$promise) {
        await payload.$promise;
      }

      // wait until complete before emitting update of modelvalue
      await Promise.all(uploads.map((u) => u.uploadFile.promise));

      this.$emit('update:modelValue', getFiles());

      // after upload has completed - revalidate to remove the "wait until upload is complete message"
      await Promise.all(uploads.map((u) => u.uploadFile.promise));
      this.validate();
    },

    uploadFile(file) {
      const fileUuid = createUuid();
      const key = `${fileUuid}.${file.name.split('.').pop()}`;

      const uploadFileHandle = uploadFile(key, file, {
        level: 'private',
        contentType: file.type,
        progressCallback: (v) => {
          upload.progress = Math.round((v.loaded / v.total) * 100);
        },
      });

      const upload = reactive({
        progress: 0,
        type: 'UPLOAD',
        status: 'IN_PROGRESS',
        file,
        filename: file.name,
        key,
        label: '',
        uploadFile: uploadFileHandle,
        index: this.filesAndUploads.length,
      });

      if (this.multiple) this.uploads.push(upload);
      else this.uploads = [upload];

      upload.uploadFile.promise
        .then(() => {
          upload.status = 'DONE';
        })
        .catch(() => {
          upload.status = 'ERROR';
        })
        .finally(() => {
          this.uploads.splice(this.uploads.indexOf(upload), 1);
        });

      return upload;
    },

    onCheckClick(e, index) {
      // prevent unticking (for some reason this would result in untiling the next one)
      e.preventDefault();
      this.files[index]?.uploadFile?.cancel();
      this.files.splice(index, 1);

      this.$emit('update:modelValue', this.multiple ? this.files : undefined);
      this.validate();
    },

    removeFile(file) {
      if (file.type === 'UPLOAD') {
        file.uploadFile?.cancel();
        this.uploads.splice(this.uploads.indexOf(file), 1);
      } else {
        this.files.splice(this.files.indexOf(file), 1);
      }

      this.$emit('update:modelValue', this.multiple ? this.files : undefined);
      this.validate();
    },
    down(file) {
      const { filesAndUploads } = this;

      // Init index: give an orderIndex based on natural ordering (if not already set)
      filesAndUploads.forEach((file, index) => {
        file.index = file.index || index;
      });

      const next = filesAndUploads[filesAndUploads.indexOf(file) + 1];

      // swap order index
      const { index } = file;
      file.index = next.index;
      next.index = index;

      this.updated();
    },
    up(file) {
      const { filesAndUploads } = this;

      // Init index: give an order index based on natural ordering (if not already set)
      filesAndUploads.forEach((file, index) => {
        file.index = file.index || index;
      });

      const previous = filesAndUploads[filesAndUploads.indexOf(file) - 1];

      // swap index
      const { index } = file;
      file.index = previous.index;
      previous.index = index;

      this.updated();
    },

    updated() {
      this.$emit('update:modelValue', this.multiple ? this.files : undefined);
    },
  },
};
</script>

<style scoped>
.progress {
  height: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-top: 1px;
}
</style>
