<template>
  <div
    class="min-vh-almost-100 bg-waves d-flex align-items-center py-1 text-center"
  >
    <div class="container w-100">
      <div class="mb-5">
        <div class="w-100">
          <h1>Page Not Found</h1>
          <p class="lead px-5">
            Unfortunately we couldn&apos;t find what you&apos;re looking for
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style scoped></style>
