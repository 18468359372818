<template>
  <div class="min-vh-75 d-flex align-items-center">
    <div class="container container-narrow">
      <!-- <h1 class="text-center mb-4">What are you uploading?</h1> -->
      <div class="row">
        <div class="col-lg-6 mb-4">
          <button
            class="btn btn-dark d-block flex-1 w-50 p-4 w-100 h-100"
            role="button"
            @click="$router.push({ name: 'AdminCreateTrackWithStems' })"
          >
            <h4>Stems</h4>
            <p>
              <i class="bi bi-sliders display-4"></i>
            </p>
            <p>
              I have produced my own stems and I would like to upload these so
              that I can play these using the online stem player and share them
              with others.
            </p>
          </button>
        </div>
        <div class="col-lg-6 mb-4">
          <button
            class="btn btn-dark d-block flex-1 w-50 p-4 w-100 h-100"
            role="button"
            :disabled="
              $acl.can('acl:track:split') === false ||
              $acl.account?.['track:split:countInSeconds'] >
                $acl.account?.['track:split:ceilingInSeconds']
            "
            @click="$router.push({ name: 'AdminCreateTrackSplitOriginal' })"
          >
            <h4>Separate Source Audio</h4>
            <p>
              <i class="bi bi-arrows-expand display-4"></i>
            </p>
            <p>
              I am uploading source (mixed) audio and I would like to split
              these into stems.
            </p>
            <span
              class="badge bg-secondary rounded-pill"
              v-if="!$acl.can('acl:track:split')"
              >Your account does not give you access to this feature</span
            >
            <span
              class="badge bg-secondary rounded-pill"
              v-if="
                $acl.can('acl:track:split') &&
                $acl.account?.['track:split:countInSeconds'] >
                  $acl.account?.['track:split:ceilingInSeconds']
              "
              >Quota Reached</span
            >
          </button>
        </div>
      </div>
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'AdminCreateTrackSelectMode',
};
</script>

<style scoped>
.border-transparent {
  border: 10px dashed transparent;
}
.mh-500 {
  min-height: 500px;
}
.btn .icon {
  transition: fill 0.15s ease-in-out;
}

.icon,
.btn:active .icon {
  fill: white;
}

.btn-check:active + .btn .icon,
.btn-check:checked + .btn .icon,
.btn-check:hover + .btn .icon {
  fill: white;
}
</style>
