<template>
  <div class="min-vh-100-lg d-flex">
    <div class="container w-100">
      <div class="row">
        <div :class="`col-lg-${12 - sidebarSize}`">
          <slot></slot>
        </div>
        <div
          class="mb-4 z-index-999 position-relative"
          :class="`col-lg-${sidebarSize}`"
        >
          <aside class="position-sticky top-navbar">
            <slot name="sidebar"></slot>
          </aside>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarLayout",
  props: {
    sidebarSize: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style scoped></style>
