import { API } from "aws-amplify";

export default async (trackuuid, { authToken } = {}) => {
  const params = [authToken ? `authToken=${authToken}` : ""].filter((e) => !!e);

  const data = await API.get(
    "cdn",
    `/tracks/${trackuuid}${params ? `?${params.join("&")}` : ""}`
  );
  return data;
};
