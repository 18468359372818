<template>
  <ul class="list-unstyled list-inline mb-0">
    <li class="list-inline-item" v-if="data.websiteUrl">
      <a
        target="blank"
        :href="
          /^https?:/.test(data.websiteUrl)
            ? data.websiteUrl
            : `//${data.websiteUrl}`
        "
        ><i class="bi bi-link"></i>
        {{ data.websiteUrl.replace(/https?:\/\//, "") }}</a
      >
    </li>
    <li class="list-inline-item" v-if="data.twitterUrl">
      <a target="blank" :href="data.twitterUrl"
        ><i class="bi bi-twitter-x"></i>
        {{ data.twitterUrl.replace(/https?:\/\//, "") }}</a
      >
    </li>
    <li class="list-inline-item" v-if="data.instagramUrl">
      <a target="blank" :href="data.instagramUrl"
        ><i class="bi bi-instagram"></i>
        {{ data.instagramUrl.replace(/https?:\/\//, "") }}</a
      >
    </li>
    <li class="list-inline-item" v-if="data.youtubeUrl">
      <a target="blank" :href="data.youtubeUrl"
        ><i class="bi bi-youtube"></i>
        {{ data.youtubeUrl.replace(/https?:\/\//, "") }}</a
      >
    </li>
    <li class="list-inline-item" v-if="data.facebookUrl">
      <a target="blank" :href="data.facebookUrl"
        ><i class="bi bi-facebook"></i>
        {{ data.facebookUrl.replace(/https?:\/\//, "") }}</a
      >
    </li>
    <li class="list-inline-item" v-if="data.soundcloudUrl">
      <a target="blank" :href="data.soundcloudUrl">
        <i class="bi bi-link"></i>
        {{ data.soundcloudUrl.replace(/https?:\/\//, "") }}</a
      >
    </li>
  </ul>
</template>

<script>
export default {
  name: "UserProfile",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
