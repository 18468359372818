<template>
  <div>
    <NavbarNavModal
      v-if="navModal"
      @close="navModal = false"
      @signOutClick="signOut"
      @signInClick="signIn"
      :isAuthenticated="isAuthenticated"
    ></NavbarNavModal>
    <nav class="navbar navbar-expand-lg fixed-top bg-body-op navbar-dark">
      <div class="container">
        <router-link
          :to="{ name: 'home' }"
          class="navbar-brand position-relative d-inline-block"
        >
          <logo class="logo"></logo>
          <span class="text-muted ms-1"
            ><em><small>Beta</small></em></span
          >
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="navModal = true"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse min-vh-100-sm"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav me-auto">
            <!-- <li class="nav-item me-2">
              <router-link :to="{ name: 'docs' }" class="btn btn-link"
                >Docs</router-link
              >
            </li> -->
            <!-- <li class="nav-item me-2">
              <router-link
                :to="{ name: 'home', hash: '#plans' }"
                class="btn btn-link bg-transparent"
                >Plans</router-link
              >
            </li> -->
          </ul>
          <ul class="navbar-nav">
            <li class="nav-item me-1">
              <div
                class="btn-toolbar"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                <div class="btn-group me-2" role="group">
                  <router-link
                    v-if="isAuthenticated"
                    :to="{ name: 'adminListTracks' }"
                    class="btn btn-dark"
                    >My Tracks</router-link
                  >
                </div>
                <div class="btn-group" role="group">
                  <router-link
                    class="btn btn-dark"
                    :to="{ name: 'adminEditAccount' }"
                    v-if="isAuthenticated"
                    >Account</router-link
                  >
                  <button
                    v-if="isAuthenticated"
                    @click="signOut"
                    :disabled="isSigningOut"
                    class="btn btn-dark"
                  >
                    Sign Out
                  </button>
                  <button
                    v-if="isAuthenticated === false"
                    @click="signIn"
                    class="btn btn-dark"
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { Auth, Hub } from 'aws-amplify';
import Logo from './Logo.vue';
import { record } from '../services/analytics';
import { LOGIN, LOGOUT } from '../consts/analytics';
import NavbarNavModal from './NavbarNavModal.vue';

export default {
  name: 'Navbar',
  components: {
    Logo,
    NavbarNavModal,
  },
  data() {
    return {
      isAuthenticated: false,
      isSigningOut: false,
      navModal: false,
    };
  },
  async mounted() {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn') {
        this.isAuthenticated = true;
        record(LOGIN);
      }
      if (data.payload.event === 'signOut') {
        this.isAuthenticated = false;
        record(LOGOUT);
      }
    });

    // Downloading can create child windows. Here we ensure that the login state changes
    // when the user logs in in or out in such a child window.
    window.addEventListener(
      'message',
      (event) => {
        if (event.data === 'child_signIn') this.isAuthenticated = true;
        if (event.data === 'child_signOut') this.isAuthenticated = false;
      },
      false,
    );

    try {
      const user = await Auth.currentAuthenticatedUser();
      this.isAuthenticated = !!user;
    } catch (err) {
      // do nothing
    }
  },
  methods: {
    async signIn() {
      this.$router.push({
        name: 'signin' /*, query: { return: location.href } */,
      });
    },
    async signOut() {
      try {
        this.isSigningOut = true;
        await Auth.signOut();
      } catch (error) {
        console.log('error signing out: ', error);
      } finally {
        this.isSigningOut = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo {
  width: 150px;
}
a {
  text-decoration: none;
}
</style>
