<template>
  <section>
    <div>
      <section
        class="min-vh-100 d-flex align-items-center bg-mixing bg-cover bg-fixed mt--navbar-lg mb-4"
      >
        <div class="container">
          <div class="w-100">
            <div class="row">
              <div class="col-lg-4 d-flex align-items-center text-center">
                <div class="w-100 my-4">
                  <h1 class="display-3">
                    Upload your stems and play them using our Free, Online,
                    Virtual, Stem-Player
                  </h1>
                  <p class="mb-4">
                    Enable your audience to enjoy your your work by embedding
                    Stem Players in your own Website and have the audio stream
                    directly to your users using our CDN &amp; infrastructure
                    &mdash; all the hard work is already done for you!
                  </p>
                  <!-- <p>
                    &mdash; Or if you do not have stems, simply upload your
                    source tracks and we will split them into stems for you!
                  </p> -->
                  <!-- <p>
                    You will be able to use our Free Online Stem Player that
                    gives you the ability to create <em>Karaoke</em> versions or
                    <em>backing tracks</em> by muting the vocals or your chosen
                    instrument.
                  </p> -->
                  <p>
                    <router-link
                      class="btn btn-primary btn-lg me-1"
                      :to="{ name: 'adminCreateTrack' }"
                      >Upload and start mixing!</router-link
                    >
                  </p>
                </div>
              </div>
              <div class="col-lg-8 d-flex align-items-center text-center">
                <div class="w-100">
                  <div class="card bg-dark-4">
                    <div class="card-body px-0">
                      <stem-player-funk-demo></stem-player-funk-demo>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- <section>
        <div class="container">
          <h1>Embed</h1>
          <mixthat-player
            controls="controls"
            src="https://d20hxdxf5jyrj9.cloudfront.net/tracks/e66c5fce-c4f9-456b-893b-805c4de017c9/stream"
          ></mixthat-player>
        </div>
      </section> -->

      <!-- <section
        class="min-vh-100 d-flex align-items-center bg-musical-instruments bg-cover bg-fixed text-dark py-4"
      >
        <div class="container">
          <div class="w-100">
            <h2>For Composers</h2>
            <p classs="lead">
              Showcase your work in stem-format and allow producers to create
              their own high quality mix right in the browser.
            </p>
          </div>
        </div>
      </section> -->

      <!-- <section
        class="min-vh-100 d-flex align-items-center bg-cover bg-fixed py-4"
      >
        <div class="container">
          <div class="w-100">
            <h2>For Musicians &amp; Bands</h2>
            <p class="lead">Upload your</p>
          </div>
        </div>
      </section> -->

      <!-- <section
        class="min-vh-100 d-flex align-items-center bg-mixing bg-cover bg-fixed mb-0 py-4"
      >
        <div class="container">
          <div class="w-100">
            <h2>For Music Teachers &amp; Students</h2>
            <p classs="lead">
              Upload your favorate music and create backing tracks by isolating
              your chosen instrument.
            </p>
          </div>
        </div>
      </section> -->

      <!-- <section
        class="min-vh-100 d-flex align-items-center bg-karaoke bg-cover bg-fixed py-4"
      >
        <div class="container">
          <div class="w-100">
            <h2>For Everyone</h2>
            <p classs="lead">
              Create <em>Karaoke</em> or <em>A capella</em> versions of your
              favorate music and sing or play along. Or simply upload your music
              and allow AI to sepate the musical-instruments giving you a new
              dimension of control when listening to your favorate tracks.
            </p>
          </div>
        </div>
      </section> -->

      <!-- <section
        class="min-vh-100 d-flex align-items-center bg-karaoke bg-cover bg-fixed"
      >
        <div class="container">
          <div class="w-100">
            <h2>For Music Business</h2>
            <p classs="lead">
              Upload your catalog and embed an unbranded, custom styled
              stem-player in your platform enabling your users to create their
              own mix of your works and download a lossless version for their
              productions.
            </p>
          </div>
        </div>
      </section> -->

      <!-- <section
        class="container min-vh-almost-100 d-block d-lg-flex align-items-center"
        ref="plans"
      >
        <div class="w-100">
          <h2 class="mb-4 text-center scroll-m-top-4" ref="pricing">Plans</h2>
          <plans></plans>
        </div>
      </section> -->
    </div>
  </section>
</template>

<script>
import StemPlayerFunkDemo from './StemPlayerFunkDemo.vue';
// import Plans from './Plans.vue';

export default {
  name: 'Home',
  components: { StemPlayerFunkDemo },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => {
        this.scrollTo(this.$route.hash.replace('#', ''));
      }, 10);
    }
  },
  methods: {
    scrollTo(ref) {
      this.$refs[ref].scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style scoped></style>
