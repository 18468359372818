import { API } from "aws-amplify";

export default async (trackuuid, { t, cache } = {}) => {
  const params = [
    t ? `t=${t}` : null,
    cache === false ? `t=${Date.now()}` : "",
  ].filter((e) => !!e);

  const data = await API.get(
    "admin",
    `/admin/tracks/${trackuuid}${params ? `?${params.join("&")}` : ""}`
  );

  return data;
};
