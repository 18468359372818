<template>
  <div class="min-vh-100 bg-waves d-flex align-items-center py-1">
    <div class="w-100 margin-top-min-navbar">
      <loader></loader>
    </div>
  </div>
</template>

<script>
import Loader from "./Loader.vue";
import { Hub } from "aws-amplify";

export default {
  name: "OauthCallback",
  components: {
    Loader,
  },
  mounted() {
    const onSignin = () => {
      Hub.remove("auth", onSignin);
      this.redirect();
    };

    Hub.listen("auth", (data) => {
      if (data.payload.event === "signIn") onSignin();
    });
  },
  methods: {
    redirect() {
      // if we login using oauth the tab will redirect and then back. We store the url so that we can continue with the download after redirect
      // ideally we'd send the customState along with the redirect url, but that does not seems to be supported with the way we use amplify-ui auth
      const src = window.sessionStorage.getItem("oauth_post_login_redirect");

      // cleanup
      window.sessionStorage.removeItem("oauth_post_login_redirect");

      if (src) {
        location.href = src;
      } else {
        this.$router.push({ name: "adminListTracks" });
      }
    },
  },
};
</script>

<style scoped></style>
