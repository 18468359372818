<template>
  <div>Not implemented</div>
</template>

<script>
export default {
  name: "AdminListTracksOnlineSearch",
};
</script>

<style scoped></style>
