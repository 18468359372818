<template>
  <Teleport to="#modal-target">
    <div>
      <div
        class="position-fixed z-index-top w-100 min-vh-100 bg-body-op top-0 start-0 h-100 overflow-auto bg-waves"
      >
        <div class="w-100">
          <button
            class="position-fixed top-0 end-0 btn-dark border-0 display-4 m-2 bg-transparent p-0"
            @click="close"
          >
            <i class="bi bi-x-lg text-white"></i>
          </button>
          <div class="container container-narrow py-4">
            <div class="m-auto"><slot :close="close"></slot></div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    open: Boolean,
    wide: Boolean,
  },
  emits: ['close'],
  methods: {
    close() {
      this.active = false;
      document.querySelector('body').classList.remove('modal-open');
      this.$emit('close');
    },
  },
  watch: {
    open: {
      handler() {
        this.active = true;
        document.querySelector('body').classList.add('modal-open');
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      active: this.showModal,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
