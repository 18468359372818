<template>
  <div
    v-if="isLoading"
    class="min-vh-100 bg-waves d-flex align-items-center py-1"
  >
    <div class="w-100 margin-top-min-navbar">
      <loader></loader>
    </div>
  </div>
  <div v-else>
    <div class="min-vh-100-lg d-flex" v-if="track">
      <div class="container w-100">
        <div class="row">
          <div class="col-lg-9">
            <!-- this representa a target box for the global player to "appear" to be placed in. We do this to ensure the player continues to be instantiated when navigating to other routes -->
            <div>
              <div>
                <div class="w-100">
                  <div class="mb-4">
                    <router-view></router-view>
                  </div>
                  <div class="text-start">
                    <div class="d-lg-flex">
                      <div class="flex-grow-1">
                        <h1 class="color-brand">
                          <div class="text-uppercase display-7 text-break pe-2">
                            {{ track.songTitle }}
                          </div>
                        </h1>
                      </div>
                      <div>
                        <action-buttons
                          :track="track"
                          :isAdmin="true"
                        ></action-buttons>
                      </div>
                    </div>
                    <p class="mb-4">
                      {{ track.description || 'No description' }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <track-stats :trackuuid="track.trackuuid"></track-stats>
              </div>
            </div>
          </div>
          <div class="col-lg-3 mb-4 z-index-999 position-relative z-index-top">
            <aside class="position-sticky top-navbar">
              <slot name="sidebar">
                <section class="bg-dark-4 list-group py-3 p-4 text-small mb-2">
                  <!-- <router-link
                    v-if="track.isPublished"
                    class="position-absolute top-0 end-0 me--3 mt--3"
                    role="button"
                    :to="{
                      name: 'publicViewTrack',
                      params: {
                        trackuuid: track.trackuuid,
                      },
                      query: {
                        t: track.modifiedTimestamp,
                      },
                    }"
                  >
                    <i class="bi bi-people-fill display-6 text-success"></i>
                  </router-link> -->

                  <dl class="row mb-0 small">
                    <dt class="col-sm-6">
                      <span>Public/Listed</span>
                    </dt>
                    <dd class="col-sm-6">
                      {{ track.isPublished ? 'Yes' : 'No' }}
                    </dd>
                    <dt class="col-sm-6">Created At</dt>
                    <dd class="col-sm-6 text-break">
                      {{
                        new Intl.DateTimeFormat('default', {
                          dateStyle: 'short',
                        }).format(track.createdTimestamp)
                      }}
                    </dd>
                    <dt class="col-sm-6">Last Modified</dt>
                    <dd class="col-sm-6 text-break">
                      {{
                        new Intl.DateTimeFormat('default', {
                          dateStyle: 'short',
                        }).format(track.modifiedTimestamp)
                      }}
                    </dd>
                  </dl>
                </section>

                <section
                  class="bg-dark-4 list-group py-3 p-4 text-small position-relative mb-2"
                >
                  <dl class="row mb-0 small">
                    <dt class="col-sm-6">Streams</dt>
                    <dd class="col-sm-6">
                      <span v-if="trackstats">{{
                        trackstats?.PLAY_MIX.total || '0'
                      }}</span>
                    </dd>
                    <dt class="col-sm-6">Downloads</dt>
                    <dd class="col-sm-6">
                      <span v-if="trackstats">{{
                        trackstats?.DOWNLOAD_MIX.total || '0'
                      }}</span>
                    </dd>
                  </dl>
                </section>

                <section class="mb-4">
                  <div class="card bg-dark-4 text-light">
                    <div class="card-footer px-2">
                      <div class="mb-1">
                        <router-link
                          href="#"
                          class="btn btn-primary d-inline-block m-0 text-nowrap w-100"
                          role="button"
                          :to="{
                            name: 'adminEditTrack',
                            params: {
                              trackuuid: track.trackuuid,
                            },
                            query: {
                              t: this.$route.query.t,
                            },
                          }"
                        >
                          Edit
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <p class="text-center">
                    <router-link
                      v-if="track.isPublished"
                      class=""
                      role="button"
                      :to="{
                        name: 'publicViewTrack',
                        params: {
                          trackuuid: track.trackuuid,
                        },
                        query: {
                          t: track.modifiedTimestamp,
                        },
                      }"
                    >
                      <small>View as Public/Listed</small>
                    </router-link>
                  </p>
                </section>
              </slot>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSeoMeta } from '@vueuse/head';
import adminGetTrack from '../../services/adminGetTrack';
import Loader from '../Loader.vue';
import TrackStats from '../TrackStats.vue';
import getTrackStats from '../../services/getTrackstats';
import ActionButtons from './ActionButtons.vue';

export default {
  name: 'ViewTrackIndex',
  components: {
    Loader,
    TrackStats,
    ActionButtons,
  },
  data() {
    return {
      track: undefined,
      isLoading: false,
      trackstats: undefined,
    };
  },
  props: {
    trackuuid: {
      type: String,
      required: true,
    },
  },
  async beforeMount() {
    const { t } = this.$route.query;

    this.track = undefined;

    try {
      this.isLoading = true;

      this.track = await adminGetTrack(this.trackuuid, {
        t,
      });

      useSeoMeta({
        title: this.track.songTitle,
        ogTitle: this.track.songTitle,
        description: this.track.description,
        ogDescription: this.track.description,
        meta: [{ name: 'description', content: this.track.description }],
      });
    } catch (err) {
      console.error(err);

      this.$notify(
        {
          title: 'Error',
          text:
            err.response?.status === 404 || err.response?.status === 403
              ? 'This track is no longer available'
              : 'We encountered a problem when loading the track',
          type: 'info',
          group: 'default',
        },
        4000,
      );
    } finally {
      this.isLoading = false;
    }

    try {
      const { data } = await getTrackStats(this.track.trackuuid);
      this.trackstats = data.events;
    } catch (error) {
      // do nothing
    }
  },
};
</script>

<style scoped>
.z-index-top {
  z-index: 9999;
}
</style>
