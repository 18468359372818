<template>
  <div>
    <slot v-if="isLoading"></slot>
    <div v-else>
      <nav class="bg-dark-4 rounded-2 text-start">
        <div class="list-group py-3 rounded-0">
          <router-link
            v-for="(track, index) in tracks"
            :key="index"
            href="#"
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3"
            :to="{
              name: routeName,
              params: {
                trackuuid: track.trackuuid,
              },
              query: {
                t: track.modifiedTimestamp,
              },
            }"
          >
            <strong>{{ track.songTitle }}</strong>
            <activity-stats
              class="float-end"
              :trackuuid="track.trackuuid"
              v-if="showStats"
            ></activity-stats>
            <span v-if="showDescription" class="ms-1">{{
              track.description
            }}</span>
          </router-link>
        </div>
      </nav>
    </div>
    <div
      class="mt-4 container text-center"
      v-if="showLoadMoreButton && hasMore"
    >
      <button
        class="btn btn-light"
        @click="loadMoreTracks"
        :disabled="isLoadingMore"
      >
        <i class="spinner-border spinner-border-sm" v-if="isLoadingMore"></i
        ><span v-else>Show More</span>
      </button>
    </div>
  </div>
</template>

<script>
import getTracks from "../services/getTracks";
import ActivityStats from "./ActivityStats.vue";

export default {
  name: "UserTrackList",
  components: { ActivityStats },
  props: {
    // username is an alias of userKey
    userKey: {
      type: String,
      required: false,
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
    showStats: {
      type: Boolean,
      default: false,
    },
    showLoadMoreButton: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
      default: "publicViewTrack",
    },
    limit: {
      type: Number,
      default: 25,
    },
  },
  data() {
    return {
      tracks: undefined,
      isLoading: true,
      hasMore: false,
      isLoadingMore: false,
    };
  },
  async beforeMount() {
    try {
      this.isLoading = true;
      await this.loadTracks();
    } catch (err) {
      // notify?
    } finally {
      this.isLoading = false;
      this.$emit("initialized");
    }
  },
  methods: {
    async loadTracks() {
      const result = await getTracks(this.userKey, { limit: this.limit });

      this.tracks = result.Items;
      this.tracksLastEvaluatedKey = result.LastEvaluatedKey;
      this.hasMore = result.LastEvaluatedKey !== undefined;
    },
    async loadMoreTracks() {
      this.isLoadingMore = true;

      try {
        const options = {
          LastEvaluatedKey: this.tracksLastEvaluatedKey,
          limit: this.limit,
        };

        const result = await getTracks(this.userKey, options);

        this.tracksLastEvaluatedKey = result.LastEvaluatedKey;
        this.hasMore = result.LastEvaluatedKey !== undefined;
        this.tracks = [...this.tracks, ...result.Items];
      } finally {
        this.isLoadingMore = false;
      }
    },
  },
};
</script>

<style scoped></style>
