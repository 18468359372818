<template>
  <div class="w-100">
    <div class="container">
      <div class="list-group py-3">
        <nav
          class="list-group text-start"
          v-if="createTrackManager.putQueue.length"
        >
          <router-link
            v-for="(trackjob, index) in createTrackManager.putQueue"
            :key="index"
            class="text-decoration-none list-group-item list-group-item-dark list-group-item-action text-truncate py-3 position-relative"
            :to="{
              name: 'adminViewTrack',
              params: {
                trackuuid: trackjob.trackuuid,
              },
            }"
          >
            <progress-list-group-item
              :progress="trackjob.uploadProgress"
              styles="d-flex justify-content-between align-items-center"
              :state="
                trackjob.success
                  ? 'success'
                  : trackjob.error
                  ? 'error'
                  : 'loading'
              "
              ><span class="text-truncate">{{
                trackjob.songTitle
              }}</span></progress-list-group-item
            >
          </router-link>
        </nav>
        <div class="min-vh-50 d-flex align-items-center" v-else>
          <div class="text-center w-100">Nothing to show here</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import ProgressListGroupItem from "../ProgressListGroupItem.vue";

export default {
  name: "AdminListTracksNewUploads",
  components: { ProgressListGroupItem },
  setup() {
    const createTrackManager = ref(inject("createTrackManager"));

    return {
      createTrackManager,
    };
  },
  data() {
    return {
      isLoading: true,
      tracks: undefined,
      hasMore: false,
      isLoadingMore: false,
      isRefreshing: false,
    };
  },
};
</script>

<style scoped></style>
