<template>
  <div class="card bg-dark-4 text-light mb-4">
    <div class="card-body">
      <file-drop-panel @drop="stageFiles" :title="title" :maxFiles="maxUploads">
        <div class="list-group mb-3 text-start">
          <progress-list-group-item
            v-for="(stagedItem, index) in createTrackManager.stage"
            :key="index"
            :progress="stagedItem.uploadProgress"
            :item="stagedItem"
            :class="{
              'list-group-item-dark': selected !== stagedItem,
              active: selected === stagedItem,
            }"
            @click="toggleSelected(stagedItem)"
            :state="stagedItem.uploadProgress === 100 ? 'success' : 'loading'"
          >
            <input
              type="text"
              class="form-control bg-transparent text-light border-0 z-index-top position-relative shadow-none p-0"
              :placeholder="`${stagedItem.file.name}`"
              :title="stagedItem.label || stagedItem.file.name"
              :class="{
                'text-black': selected === stagedItem,
              }"
              v-model="stagedItem.label"
              ref="input"
            />
          </progress-list-group-item>
        </div>

        <div
          class="mb-3 bg-dark-4 p-3 rounded-3"
          v-if="createTrackManager.stage.length"
        >
          <div class="input-group">
            <button
              class="btn btn-light"
              @click.stop="cancelUpload"
              :disabled="!selected"
            >
              Eject
            </button>
            <input
              type="text"
              class="form-control shadow-none"
              placeholder="Song Title"
              aria-label="The song name"
              v-model="songTitle"
              :disabled="createTrackManager.stage.length < 2"
              v-if="mode === 'stem'"
            />
            <select
              v-else
              class="form-select"
              aria-label="Default select example"
              id="splitStemModel"
              v-model="model"
            >
              <option value="demucs:htdemucs">
                4 Stems (Drums, Bass Vocals, Other)
              </option>
              <option value="demucs:htdemucs_6s">
                6 Stems (Drums, Bass Vocals, Piano, Guitar, Other)
              </option>
            </select>
            <button
              class="btn btn-light bg-brand border-brand"
              id="selectFiles"
              @click="createTrack"
              :disabled="
                (mode === 'stem' && !songTitle) ||
                createTrackManager.stage.find((stageItem) => !stageItem.label)
              "
            >
              Create
            </button>
          </div>
        </div>

        <p
          class="alert alert-dark bg-dark-4 text-light border-0 mb-4"
          role="alert"
          v-if="
            mode == 'stem' &&
            createTrackManager.stage.length > 0 &&
            createTrackManager.stage.length < 2
          "
        >
          <i class="bi bi-exclamation-triangle-fill"></i> Please add more stems:
          To create a track you need at least 2.
        </p>

        <p
          class="alert alert-dark bg-dark-4 text-light border-0 mb-4"
          role="alert"
          v-if="mode == 'stem' && createTrackManager.stage.length > 20"
        >
          <i class="bi bi-exclamation-triangle-fill"></i> Please remove some
          stems: you can only add 20 stems to a track
        </p>

        <p
          class="text-center"
          v-if="
            createTrackManager.stage.length ||
            createTrackManager.putQueue.length
          "
        >
          <small class="badge bg-dark text-light"
            >Do not refresh the browser while uploading</small
          >
        </p>
      </file-drop-panel>
    </div>
  </div>
</template>

<script>
import { ref, inject, reactive } from 'vue';
import ProgressListGroupItem from '../ProgressListGroupItem.vue';
import FileDropPanel from '../FileDropPanel.vue';

export default {
  name: 'AdminCreateTrackUpload',
  components: { ProgressListGroupItem, FileDropPanel },
  props: {
    mode: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    maxUploads: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      selected: undefined,
      songTitle: undefined,
      model: 'demucs:htdemucs',
    };
  },
  setup() {
    const createTrackManager = ref(inject('createTrackManager'));

    return {
      createTrackManager,
    };
  },
  beforeMount() {
    // redirect when navigating directly + when the account can no longer split
    if (
      this.mode === 'split' &&
      (this.$acl.can('acl:track:split') === false ||
        this.$acl.account?.['track:split:countInSeconds'] >
          this.$acl.account?.['track:split:ceilingInSeconds'])
    ) {
      this.$router.replace({
        name: 'adminCreateTrack',
      });
    }
  },
  methods: {
    stageFiles({ acceptFiles, rejectReasons }) {
      if (rejectReasons) {
        const errors = [].concat.apply(
          [],
          rejectReasons.map(({ errors }) => errors),
        );

        if (errors.find((error) => error.code === 'too-many-files')) {
          this.showTooManyUploadsNotification();
          return;
        }
      }

      if (
        this.createTrackManager.stage.length + acceptFiles.length >
        this.maxUploads
      ) {
        this.showTooManyUploadsNotification();
        return;
      }

      this.createTrackManager.stageItem(
        ...acceptFiles.map((file) => reactive({ file })),
      );
    },
    showTooManyUploadsNotification() {
      this.$notify(
        {
          title: 'Error',
          text: `Too many files selected: you can only upload ${this.maxUploads} files simultaneously`,
          type: 'error',
          group: 'default',
        },
        4000,
      );
    },
    toggleSelected(stagedItem) {
      if (this.selected === stagedItem) this.selected = undefined;
      else this.selected = stagedItem;
    },
    cancelUpload() {
      this.createTrackManager.remove(this.selected);
      this.selected = undefined;
    },
    async createTrack() {
      try {
        if (this.mode === 'stem') {
          this.createTrackManager.createTrackFromStagedItems({
            songTitle: this.songTitle,
          });

          this.songTitle = undefined;
        } else if (this.mode === 'split') {
          this.createTrackManager.createIndividualTrackFromStagedItems({
            model: this.model,
          });
        }
      } catch (err) {
        let text = 'Something went wrong. Please try again later.';
        if (err.response?.status === 409) {
          text =
            'You have reached the maximum number of tracks in your account. Please contact us on hello@sound.ws to discuss options';
        }

        this.$notify(
          {
            title: 'Error',
            text,
            type: 'error',
            group: 'default',
          },
          4000,
        );
      }
    },
  },
};
</script>

<style scoped>
.border-transparent {
  border: 10px dashed transparent;
}
.mh-500 {
  min-height: 500px;
}
.btn .icon {
  transition: fill 0.15s ease-in-out;
}

.icon,
.btn:active .icon {
  fill: black;
}

.btn-check:active + .btn .icon,
.btn-check:checked + .btn .icon,
.btn-check:hover + .btn .icon {
  fill: black;
}
</style>
