<template>
  <div>
    <track-options-modal
      v-if="showOptions"
      :track="track"
      :isAdmin="isAdmin"
      @close="showOptions = false"
    ></track-options-modal>
    <router-link
      :to="{
        name: 'adminViewTrack',
        params: {
          trackuuid: track.trackuuid,
        },
      }"
      class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 text-start d-flex"
    >
      <button
        class="btn btn-outline-dark my--2 ms--2 me-2"
        @click.prevent="play(track)"
      >
        <i class="bi bi-play-fill"></i>
      </button>
      <div class="flex-grow-1 overflow-hidden text-truncate pe-2">
        <strong>{{ track.songTitle }}</strong>
        <span class="ms-1 text-break" v-if="track.description"
          ><span class="text-white-50">/</span> {{ track.description }}</span
        >
      </div>
      <button
        class="btn btn-outline-dark my--2 me--2"
        @click.prevent="showOptions = !showOptions"
      >
        <i class="bi bi-three-dots-vertical"></i>
      </button>
    </router-link>
  </div>
</template>

<script>
import TrackOptionsModal from "./TrackOptionsModal.vue";

export default {
  name: "TrackRow",
  components: { TrackOptionsModal },
  data() {
    return {
      showOptions: false,
    };
  },
  props: {
    track: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async playInline(trackrow) {
      this.$player.playMix(trackrow, {
        autoplay: true,
        isAdmin: this.isAdmin,
      });
    },

    async play(trackrow) {
      this.$router.push({
        name: "adminMixTrack",
        params: { trackuuid: trackrow.trackuuid },
        query: { t: trackrow.modifiedTime },
      });
    },
  },
};
</script>

<style scoped></style>
