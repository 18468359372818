import { API, Cache } from "aws-amplify";

const key = "/admin/account";

export default async ({ cache = false } = {}) => {
  if (cache) {
    const cached = Cache.getItem(key);
    if (cached) return cached;
  }

  const { data } = await API.get("admin", `/admin/account`);

  // if (cache) {
  Cache.setItem(key, data, {
    expires: new Date(Date.now() + 3600),
  });
  // }

  return data;
};
