<template>
  <form
    @submit="submit"
    autocomplete="off"
    id="contact-form"
    class="contact-form"
  >
    <input type="hidden" name="formName" value="MixThatNewsletter" />
    <div class="form-group mb-3">
      <label class="required form-label" for="trackFormSongTitle"
        >Sign up for our newsletter</label
      >
      <div class="input-group">
        <input
          type="email"
          class="form-control bg-dark text-light border-light"
          aria-label="Sign up for our newsletter with your email address"
          aria-describedby="button-newsletter"
          name="email"
          required
          :disabled="isSubmitting || showThankyou"
        />
        <button
          class="btn btn-outline-light"
          type="submit"
          id="button-newsletter"
          v-show="!isSubmitting && !showThankyou"
        >
          Send
        </button>
        <button
          class="btn btn-outline-secondary"
          type="button"
          disabled
          v-show="isSubmitting"
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Moment
        </button>
        <button
          class="btn btn-outline-secondary"
          type="button"
          disabled
          v-show="showThankyou"
        >
          Thank you
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "NewsletterForm",
  methods: {
    async submit(e) {
      e.preventDefault();
      this.isSubmitting = true;
      const data = new URLSearchParams(new FormData(e.target));

      try {
        await fetch("https://stemplayer-js.com/api/contact/", {
          method: "post",
          body: data,
          mode: "no-cors",
        });
      } catch (err) {
        console.error("An error occured", err);
      } finally {
        this.isSubmitting = false;
        e.target.reset();
        this.showThankyou = true;
        setTimeout(() => {
          this.showThankyou = false;
        }, 5000);
      }
    },
  },
  data() {
    return {
      isSubmitting: this.isSubmitting,
      showThankyou: this.showThankyou,
    };
  },
};
</script>

<style scoped></style>
