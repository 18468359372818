import { Storage } from "aws-amplify";
import { maxConcurrentUploads } from "../../config";

let inProgress = 0;

const uploadFile = (key, file, opts) => {
  return Storage.put(key, file, {
    level: "private",
    contentType: file.type,
    ...opts,
  });
};

export default (...args) => {
  // if there are too many in progress, wait until the number drops
  let i;
  let uploadPromise;

  const promise = new Promise((done) => {
    i = setInterval(() => {
      if (inProgress < maxConcurrentUploads) {
        clearInterval(i);

        inProgress += 1;

        uploadPromise = uploadFile(...args);

        uploadPromise
          .then(() => {
            inProgress -= 1;
            done();
          })
          .catch((err) => {
            if (Storage.isCancelError(err)) {
              console.error(err);
            } else throw err;
          });
      }
    }, 100);
  });

  return {
    promise,
    cancel: () => Storage.cancel(uploadPromise),
  };
};
