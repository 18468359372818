<template>
  <auth @ready="loadAccount()">
    <form
      autocomplete="off"
      id="account-form"
      class="account-form text-start"
      @submit.prevent="submit($event, scope)"
    >
      <div class="min-vh-almost-100 d-flex align-items-center" v-if="isLoading">
        <div class="w-100 margin-top-min-navbar">
          <loader></loader>
        </div>
      </div>
      <sidebar-layout v-else :sidebarSize="4">
        <template v-slot:sidebar
          ><div class="list-group mb-2 d-none d-lg-block">
            <a
              href="#general"
              @click.prevent="scrollTo('general')"
              class="list-group-item list-group-item-action list-group-item-dark"
              aria-current="true"
            >
              General
            </a>

            <a
              href="#permissions"
              @click.prevent="scrollTo('permissions')"
              class="list-group-item list-group-item-action list-group-item-dark"
              >Account Permissions</a
            >

            <a
              href="#quota"
              @click.prevent="scrollTo('quota')"
              class="list-group-item list-group-item-action list-group-item-dark"
              >Account Limits</a
            >
            <!-- <a
              href="#api"
              @click.prevent="scrollTo('quota')"
              class="list-group-item list-group-item-action list-group-item-dark"
              >API Access</a
            > -->
            <a
              href="#profileInfo"
              @click.prevent="scrollTo('profileInfo')"
              class="list-group-item list-group-item-action list-group-item-dark"
              >Profile Info</a
            >
          </div>

          <div class="card bg-dark-4 text-light">
            <div class="card-body p-2">
              <div class="text-end">
                <button
                  class="btn btn-primary w-100"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  <span v-if="isSubmitting">
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Loading...</span>
                  </span>
                  <span v-else>Save</span>
                </button>
              </div>
            </div>
          </div>
        </template>

        <div class="min-vh-almost-100 d-flex align-items-center">
          <div class="w-100">
            <div class="d-flex align-items-center min-vh-100">
              <div class="w-100">
                <section class="card bg-dark-4 text-light mb-4">
                  <div
                    class="card-header text-secondary mb-0 text-end border-0"
                  >
                    <h5 class="mb-0 scroll-m-top-4" ref="general">General</h5>
                  </div>
                  <div class="card-body">
                    <div class="form-group mb-3 position-relative">
                      <label
                        class="required form-label"
                        for="accountFormUsername"
                        >Account Name / Username
                      </label>
                      <input
                        name="accountFormUsername"
                        class="form-control"
                        id="accountFormUsername"
                        required="required"
                        minlength="2"
                        maxlength="20"
                        v-model="model.username"
                        :disabled="hasUsername"
                        pattern="[A-Za-z0-9\-_]{2,15}"
                        title="A username may contain letters, numbers - and _ and not contain any spaces and be between 2 and 15 characters long"
                      />
                      <div
                        id="emailHelp"
                        class="form-text text-white-50 text-end"
                      >
                        Warning: this cannot be changed once set.
                      </div>
                    </div>
                    <div class="form-group mb-3 position-relative">
                      <label class="form-label" for="accountFormUsername"
                        >Organisation Name
                      </label>
                      <input
                        name="accountFormOrganisationName"
                        class="form-control"
                        id="accountFormOrganisationName"
                        minlength="2"
                        maxlength="25"
                        v-model="model.organisationName"
                      />
                    </div>
                    <div class="form-group mb-3">
                      <label class="form-label" for="accountFormWebsite"
                        >Website</label
                      >
                      <input
                        name="accountFormWebsite"
                        class="form-control"
                        id="accountFormWebsite"
                        placeholder="https://www.my-company-website.com/..."
                        v-model="model.websiteUrl"
                      />
                    </div>
                  </div>
                </section>
                <section class="card bg-dark-4 text-light mb-4">
                  <div
                    class="card-header text-secondary mb-0 text-end border-0"
                  >
                    <h5 class="mb-0 scroll-m-top-4" ref="permissions">
                      Account Permissions
                    </h5>
                  </div>
                  <div class="card-body">
                    <dl class="row mb-0 small">
                      <dt class="col-sm-6">Download High Quality Mix</dt>
                      <dd class="col-sm-6">
                        <i
                          class="bi"
                          :class="
                            $acl.can('acl:track:downloadMix')
                              ? 'bi-check text-success'
                              : 'bi-x text-danger'
                          "
                        ></i>
                      </dd>
                      <!-- <dt class="col-sm-6">Download Stems</dt>
                      <dd class="col-sm-6">
                        <i
                          class="bi"
                          :class="
                            $acl.can('acl:track:downloadStems')
                              ? 'bi-check text-success'
                              : 'bi-x text-danger'
                          "
                        ></i>
                      </dd> -->
                      <dt class="col-sm-6">
                        Separate Source Audio (Split Stems)
                      </dt>
                      <dd class="col-sm-6">
                        <i
                          class="bi"
                          :class="
                            $acl.can('acl:track:split')
                              ? 'bi-check text-success'
                              : 'bi-x text-danger'
                          "
                        ></i>
                      </dd>
                      <!-- <dt class="col-sm-6">View Dashboard Stats</dt>
                      <dd class="col-sm-6">
                        <i
                          class="bi"
                          :class="
                            $acl.can('acl:track:viewStats')
                              ? 'bi-check text-success'
                              : 'bi-x text-danger'
                          "
                        ></i>
                      </dd> -->
                      <!-- <dt class="col-sm-6">Embed Private Tracks</dt>
                      <dd class="col-sm-6">
                        <i
                          class="bi"
                          :class="
                            $acl.can('acl:track:private:embed')
                              ? 'bi-check text-success'
                              : 'bi-x text-danger'
                          "
                        ></i>
                      </dd> -->
                      <!-- <dt class="col-sm-6">Share Private Tracks</dt>
                      <dd class="col-sm-6">
                        <i
                          class="bi"
                          :class="
                            $acl.can('acl:track:private:share')
                              ? 'bi-check text-success'
                              : 'bi-x text-danger'
                          "
                        ></i>
                      </dd> -->
                    </dl>
                    <router-link
                      :to="{
                        name: 'contact',
                      }"
                      ><small>Contact us to discuss options</small></router-link
                    >
                  </div>
                </section>
                <section class="card bg-dark-4 text-light mb-4">
                  <div
                    class="card-header text-secondary mb-0 text-end border-0"
                  >
                    <h5 class="mb-0 scroll-m-top-4" ref="quota">
                      Account Limits
                    </h5>
                  </div>
                  <div class="card-body">
                    <dl class="row mb-0 small">
                      <dt class="col-sm-6">Uploaded Tracks</dt>
                      <dd class="col-sm-6">
                        {{ model['track:count'] || 0 }} /
                        {{ model['track:countCeiling'] }} ({{
                          Math.ceil(
                            ((model['track:count'] || 0) /
                              model['track:countCeiling']) *
                              100,
                          )
                        }}%)
                      </dd>
                      <dt class="col-sm-6">File Storage</dt>
                      <dd class="col-sm-6">
                        {{
                          Math.round(
                            model['storage:sizeInBytes'] / 1024 / 1024 || 0,
                          )
                        }}
                        MB /
                        {{
                          Math.round(
                            model['storage:ceilingInBytes'] / 1024 / 1024,
                          )
                        }}
                        MB ({{
                          Math.ceil(
                            ((model['storage:sizeInBytes'] || 0) /
                              model['storage:ceilingInBytes']) *
                              100,
                          )
                        }}%)
                      </dd>
                      <dt class="col-sm-6">Audio Source Separation</dt>
                      <dd class="col-sm-6">
                        {{ model['track:split:countInSeconds'] || 0 }} Seconds /
                        {{ model['track:split:ceilingInSeconds'] }} Seconds ({{
                          Math.ceil(
                            ((model['track:split:countInSeconds'] || 0) /
                              model['track:split:ceilingInSeconds']) *
                              100,
                          )
                        }}%)
                      </dd>
                    </dl>
                    <router-link
                      :to="{
                        name: 'contact',
                      }"
                      ><small
                        >Contact us to request an increase</small
                      ></router-link
                    >
                  </div>
                </section>

                <!-- <section class="card bg-dark-4 text-light mb-4">
                  <div
                    class="card-header text-secondary mb-0 text-end border-0"
                  >
                    <h5 class="mb-0 scroll-m-top-4" ref="api">API Access</h5>
                  </div>
                  <div class="card-body" v-if="!$acl.can('acl:api')">
                    <p class="text-center" role="alert">
                      <i class="bi bi-exclamation-triangle-fill"></i> API Access
                      is not enabled in your account. Please
                      <router-link
                        :to="{
                          name: 'contact',
                        }"
                        ><small
                          >contact us to request access</small
                        ></router-link
                      >.
                    </p>
                  </div>
                  <div class="card-body" v-else>
                    <button
                      v-if="!APIKey"
                      class="btn btn-dark w-100"
                      type="button"
                      @click="createAPIKey"
                    >
                      Get API Key
                    </button>
                    <dl v-else class="row mb-0 small">
                      <dt class="col-sm-4">API Key</dt>
                      <dd class="col-sm-8">
                        <copy-to-clipboard
                          :rows="3"
                          :value="APIKey.token"
                        ></copy-to-clipboard>
                      </dd>
                      <dt class="col-sm-4">Valid until</dt>
                      <dd class="col-sm-8">
                        {{ APIKey.expires }}
                      </dd>
                    </dl>
                  </div>
                </section> -->
                <section class="card bg-dark-4 text-light mb-4">
                  <div
                    class="card-header text-secondary mb-0 text-end border-0"
                  >
                    <h5 class="mb-0 scroll-m-top-4" ref="profileInfo">
                      Your Profile Info
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <label
                        class="form-label"
                        for="accountFormOrganisationName"
                        >About You
                      </label>

                      <textarea
                        rows="5"
                        name="description"
                        type="text"
                        class="form-control"
                        id="accountFormBio"
                        maxlength="350"
                        v-model="model.bio"
                      />
                      <div
                        id="descriptionHelp"
                        class="form-text text-muted text-end"
                      >
                        max 350 characters
                      </div>
                    </div>
                    <fieldset class="mb-3">
                      <div class="form-group mb-3">
                        <label class="form-label" for="accountFormTwitter"
                          ><i class="bi bi-twitter-x me-1"></i> Twitter</label
                        >
                        <input
                          type="url"
                          name="accountFormTwitter"
                          class="form-control"
                          id="accountFormTwitter"
                          placeholder="https://twitter.com/..."
                          v-model="model.twitterUrl"
                          pattern="^https://twitter.com"
                          title="Must start with https://twitter.com"
                        />
                      </div>
                      <div class="form-group mb-3">
                        <label class="form-label" for="accountFormInstagram"
                          ><i class="bi bi-instagram me-1"></i> Instagram</label
                        >
                        <input
                          type="url"
                          name="accountFormInstagram"
                          class="form-control"
                          id="accountFormInstagram"
                          placeholder="https://www.instagram.com/..."
                          v-model="model.instagramUrl"
                          pattern="^https://www.instagram.com"
                          title="Must start with https://www.instagram.com"
                        />
                      </div>
                      <div class="form-group mb-3">
                        <label class="form-label" for="accountFormYoutube"
                          ><i class="bi bi-youtube me-1"></i> Youtube</label
                        >
                        <input
                          type="url"
                          name="accountFormYoutube"
                          class="form-control"
                          placeholder="https://www.youtube.com/..."
                          id="accountFormYoutube"
                          v-model="model.youtubeUrl"
                          pattern="^https://www.youtube.com"
                          title="Must start with https://www.youtube.com"
                        />
                      </div>
                      <div class="form-group mb-3">
                        <label class="form-label" for="accountFormFacebook"
                          ><i class="bi bi-facebook me-1"></i> Facebook</label
                        >
                        <input
                          type="url"
                          name="accountFormFacebook"
                          class="form-control"
                          id="accountFormFacebook"
                          placeholder="https://www.facebook.com/..."
                          v-model="model.facebookUrl"
                          pattern="^https://www.facebook.com"
                          title="Must start with https://www.facebook.com"
                        />
                      </div>
                      <div class="form-group">
                        <label class="form-label" for="accountFormSoundcloud">
                          <i class="bi bi-link"></i> Soundcloud</label
                        >
                        <input
                          type="url"
                          name="accountFormSoundcloud"
                          class="form-control"
                          id="accountFormSoundcloud"
                          placeholder="https://soundcloud.com/..."
                          v-model="model.soundcloudUrl"
                          pattern="^https://soundcloud.com"
                          title="Must start with https://soundcloud.com"
                        />
                      </div>
                    </fieldset>
                  </div>
                </section>
              </div>
            </div>

            <!-- <storage-monitor
        class="position-absolute bottom-0 end-0 text-muted small p-2"
      ></storage-monitor> -->
          </div>
        </div>
      </sidebar-layout>
    </form>
  </auth>
</template>

<script>
import Auth from './Auth.vue';
import Loader from './Loader.vue';
import adminGetAccount from '../services/adminGetAccount';
import adminPutAccount from '../services/adminPutAccount';
import SidebarLayout from './SidebarLayout.vue';
// import CopyToClipboard from './CopyToClipboard.vue';

export default {
  name: 'AdminEditAccount',
  components: { Auth, Loader, SidebarLayout },
  data() {
    return {
      isSubmitting: this.isSubmitting,
      showThankyou: this.showThankyou,
      model: undefined,
      isLoading: this.isLoading,
      hasUsername: this.hasUsername,
      APIKey: undefined,
    };
  },
  beforeMount() {
    this.load();
  },
  methods: {
    async load() {
      if (this.model) return false;

      try {
        this.isLoading = true;
        this.model = (await adminGetAccount()) || {};
        this.hasUsername = !!this.model.username;
      } catch (err) {
        console.log(err);
        if (err.response?.status !== 404) {
          // 404 === the account object does not exist - not a problem
          this.$notify(
            {
              title: 'Error',
              text: 'Something went wrong. Please try again later.',
              type: 'error',
              group: 'default',
            },
            4000,
          );
        }
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      this.isSubmitting = true;

      try {
        await adminPutAccount(this.model);

        this.$notify(
          {
            title: 'Info',
            text: 'Your information has been stored',
            type: 'info',
            group: 'default',
          },
          4000,
        );

        //  scope.close();
      } catch (err) {
        this.$notify(
          {
            title: 'Error',
            text:
              err.message || 'Something went wrong. Please try again later.',
            type: 'error',
            group: 'default',
          },
          4000,
        );
      } finally {
        this.isSubmitting = false;
      }
    },
    scrollTo(ref) {
      this.$refs[ref].scrollIntoView({ behavior: 'smooth' });
    },
    createAPIKey() {
      this.APIKey = { token: 'fdfdfd', expires: new Date() };
    },
  },
};
</script>

<style scoped></style>
