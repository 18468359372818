<template>
  <div class="text-center text-light">
    <div class="spinner-border" role="status" style="width: 3rem; height: 3rem">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style scoped></style>
