<template>
  <div class="container">
    <auth>
      <account>
        <div>
          <div class="nav nav-pills mb-2">
            <router-link
              href="#"
              class="nav-link"
              role="button"
              :to="{
                name: 'adminListTracks',
              }"
            >
              Search
            </router-link>
            <router-link
              href="#"
              class="nav-link"
              role="button"
              :to="{
                name: 'adminCreateTrack',
              }"
            >
              Upload
            </router-link>
            <router-link
              v-if="createTrackManager.putQueue.length"
              href="#"
              class="nav-link"
              role="button"
              :to="{
                name: 'adminListTracksNewUploads',
              }"
            >
              Show uploads
            </router-link>
          </div>

          <router-view></router-view>
        </div>
      </account>
    </auth>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import Auth from "../Auth.vue";
import Account from "../Account.vue";

export default {
  name: "AdminListTracks",
  components: { Auth, Account },
  setup() {
    const createTrackManager = ref(inject("createTrackManager"));

    return {
      createTrackManager,
    };
  },
};
</script>

<style scoped></style>
