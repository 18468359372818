export const PAGE_LOAD = 'PAGE_LOAD';
export const PLAY_MIX = 'PLAY_MIX';
export const PLAY_MIX_FAIL = 'PLAY_MIX_FAIL';
export const PLAYER_PLAY = 'PLAYER_PLAY';
export const PLAYER_PAUSE = 'PLAYER_PAUSE';
export const PLAYER_SEEK = 'PLAYER_SEEK';
export const PLAYER_ERROR = 'PLAYER_ERROR';
export const PLAYER_END = 'PLAYER_END';
export const PLAYER_LOADING_START = 'PLAYER_LOADING_START';
export const PLAYER_LOADING_END = 'PLAYER_LOADING_END';
export const PLAYER_TIMEUPDATE = 'PLAYER_TIMEUPDATE';
export const DOWNLOAD_MIX = 'DOWNLOAD_MIX';
export const DOWNLOAD_MIX_FAIL = 'DOWNLOAD_MIX_FAIL';
export const DOWNLOAD_MASTER = 'DOWNLOAD_MASTER';
export const DOWNLOAD_MASTER_FAIL = 'DOWNLOAD_MASTER_FAIL';
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const NAVIGATE = 'NAVIGATE';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
