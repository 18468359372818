<template>
  <div
    v-bind="getRootProps()"
    class="min-vh-75 align-items-center border-4-dashed-muted d-flex text-center"
  >
    <div class="w-100 p-4" @click.stop>
      <h2 class="mb-2">{{ title }}</h2>
      <input v-bind="getInputProps()" />
      <p v-if="isDragActive">Now drop them here ...</p>
      <p v-else>
        Drop your audio here &mdash; or click the button below to select them.
      </p>
      <button @click.stop="open" class="btn btn-light mb-4">
        Select Audio
      </button>
      <slot @click.stop></slot>
    </div>
  </div>
</template>

<script>
import config from "../../config";
import { useDropzone } from "vue3-dropzone";

export default {
  name: "FileDropPanel",
  props: {
    title: {
      type: String,
      default: "Upload your Audio",
    },
    accept: {
      type: Array,
      default() {
        return [".mp3", ".wav", ".aiff", ".flac"];
      },
    },
    maxUploadFileSize: {
      type: Number,
      default: config.maxUploadFileSize,
    },
    maxFiles: {
      type: Number,
      default: 5,
    },
  },
  emits: ["drop"],
  setup(props, context) {
    const onDrop = (acceptFiles, rejectReasons) => {
      context.emit("drop", { acceptFiles, rejectReasons });
    };

    const { getRootProps, getInputProps, ...rest } = useDropzone({
      onDrop,
      multiple: true,
      accept: props.accept,
      maxSize: props.maxUploadFileSize,
      maxFiles: props.maxFiles,
    });

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
};
</script>

<style scoped></style>
