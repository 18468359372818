import { reactive } from "vue";
import uploadFile from "@/services/uploadFile";
import adminPutTrack from "@/services/adminPutTrack";
import { v4 as createUuid } from "uuid";

class CreateTrackJob {
  constructor({ songTitle, trackuuid, items } = {}) {
    this.songTitle = songTitle;
    this.trackuuid = trackuuid || createUuid();
    this.items = items || [];
  }

  get uploadProgress() {
    return (
      this.items.map((item) => item.uploadProgress).reduce((a, b) => a + b, 0) /
      this.items.length
    );
  }
}

class CreateTrackManager {
  constructor() {
    this.stage = [];
    this.putQueue = [];
  }

  stageItem(...items) {
    items.forEach((item) => {
      const id = `${item.file.path}:${item.file.lastModified}`;

      item.label = item.file.name;

      if (
        !this.stage.find((e) => {
          return e.id === id;
        })
      ) {
        item.id = id;
        this.stage.push(item);
      }

      this.upload(item);
    });
  }

  get length() {
    return this.stage.length + this.putQueue.length;
  }

  getAt(index) {
    return this.stage[index];
  }

  remove(...items) {
    items.forEach((e) => {
      // cancel the upload
      e.uploadFile.cancel();

      // remove from the stage
      this.stage.splice(this.stage.indexOf(e), 1);
    });
  }

  upload(item) {
    const uploaduuid = createUuid();
    const { file } = item;

    item.uploaduuid = uploaduuid;
    item.key = `${uploaduuid}.${file.name.split(".").pop()}`;
    item.uploadProgress = 0;

    item.uploadFile = uploadFile(item.key, file, {
      level: "private",
      contentType: file.type,
      progressCallback: (v) => {
        item.uploadProgress = Math.round((v.loaded / v.total) * 100);
      },
    });
  }

  /**
   * Creates a single track with the staged items as stems
   */
  async createTrackFromStagedItems({ songTitle }) {
    const job = reactive(new CreateTrackJob({ songTitle, items: this.stage }));

    // clear the stage
    this.stage = [];

    // stick the job on the createTrackQueue
    this.putQueue.push(job);

    try {
      // wait until all files
      await Promise.all(job.items.map((item) => item.uploadFile.promise));

      await adminPutTrack(job.trackuuid, {
        songTitle: job.songTitle,
        stems: job.items.map((item, index) => ({
          key: item.key,
          label: item.label,
          filename: item.file.name,
          index,
        })),
      });

      job.success = true;
    } catch (error) {
      job.fail = true;
      job.error = error;
      throw error;
    }

    return job;
  }

  /**
   * Creates multiple tracks (to split into stems)
   */
  createIndividualTrackFromStagedItems({ model }) {
    const promises = this.stage.map(async (item) => {
      const job = reactive(
        new CreateTrackJob({ songTitle: item.label, items: [item] })
      );

      // stick the job on the createTrackQueue
      this.putQueue.push(job);

      try {
        // wait until all files have been uploaded (just one in this case)
        await Promise.all(job.items.map((item) => item.uploadFile.promise));

        await adminPutTrack(job.trackuuid, {
          songTitle: job.songTitle,
          ["split:model"]: model,
          audio: {
            uploadedKey: item.key,
            filename: item.file.name, //store the original local filename
            type: "UPLOAD",
          },
        });

        job.success = true;
      } catch (error) {
        job.fail = true;
        job.error = error;
        throw error;
      }

      return job;
    });

    // clear the stage
    this.stage = [];

    return promises;
  }
}

export default CreateTrackManager;
