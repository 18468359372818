<template>
  <div class="min-vh-player d-flex align-items-center" v-if="isLoading">
    <div class="w-100 p-4">
      <loader v-if="!hideLoader"></loader>
    </div>
  </div>
  <div class="min-vh-player d-flex align-items-center" v-if="isNotReady">
    <div class="w-100 p-4 text-center text-light">
      <div role="alert">
        <p class="w-75 mx-auto mb-2">
          The audio could not be streamed. If you have recently created this
          track, then this is likely because the audio is still being prepared.
          Stem separation will normally take a few minutes.
        </p>
        <button class="btn btn-light" id="selectFiles" @click="loadTrack()">
          <i class="bi bi-arrow-clockwise"></i>
          Retry
        </button>
      </div>
    </div>
  </div>
  <div class="min-vh-player d-flex align-items-center" v-if="isError">
    <div class="w-100 p-4 text-center text-light">
      <div role="alert">
        <i class="bi bi-exclamation-triangle-fill"></i> The streaming audio
        could not be loaded
      </div>
    </div>
  </div>

  <stemplayer-js
    v-if="track"
    ref="player"
    :autoplay="autoplay"
    :sample-rate="acSamplerate"
    :id="playerId"
    :data-trackuuid="track.trackuuid"
    max-height="450"
  >
    <stemplayer-js-controls
      :label="track.songTitle"
      slot="header"
    ></stemplayer-js-controls>
    <stemplayer-js-stem
      v-for="(stem, index) in track.stems"
      :id="stem.uploaduuid"
      :key="index"
      :label="stem.label"
      :src="canPlayOgg ? stem['hls:ogg'] : stem['hls:mp3']"
      :waveform="stem.waveform"
    >
    </stemplayer-js-stem>
  </stemplayer-js>
</template>

<script>
import { v4 as createUuid } from "uuid";
import { cdnEndpoint, audioPresets } from "../../config";
// import { recordPlayerEvents } from "../services/analytics";
import streamTrack from "../services/streamTrack";
import adminStreamTrack from "../services/adminStreamTrack";
import Loader from "./Loader.vue";
import { record } from "../services/analytics";
import { PLAY_MIX, PLAY_MIX_FAIL } from "../consts/analytics";

const myAudio = document.createElement("audio");
const canPlayOgg = myAudio.canPlayType("audio/ogg");

export default {
  name: "StemPlayer",
  components: { Loader },
  props: {
    trackuuid: {
      type: String,
      required: true,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    authToken: {
      type: String,
      required: false,
    },
    hideLoader: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["load"],
  data() {
    return {
      acSamplerate: audioPresets.acSampleRate,
      isNotReady: false,
      cdnEndpoint,
      playerId: createUuid(),
      track: undefined,
      streamUrls: undefined,
      isLoading: false,
      isError: false,
    };
  },
  computed: {
    player() {
      return this.$refs.player;
    },
    canPlayOgg() {
      return canPlayOgg;
    },
  },
  async beforeMount() {
    try {
      await this.loadTrack();

      if (!this.isNotReady) {
        record(PLAY_MIX, {
          trackuuid: this.trackuuid,
          isAdmin: this.isAdmin ? true : undefined,
        });
      }
    } catch (error) {
      this.$notify(
        {
          title: "Error",
          text: "We encountered a problem when playing the track",
          type: "info",
          group: "default",
        },
        4000
      );

      record(PLAY_MIX_FAIL, {
        trackuuid: this.trackuuid,
        isAdmin: this.isAdmin ? true : undefined,
      });
    }
  },
  methods: {
    async loadTrack() {
      try {
        this.isLoading = true;
        this.isNotReady = false;

        const provider = this.isAdmin ? adminStreamTrack : streamTrack;

        // get the track with streaming urls
        this.track = await provider(this.trackuuid, {
          authToken: this.authToken,
          cache: false,
        });

        this.$emit("load", this.track);

        setTimeout(() => {
          // view naviages to play mix if the current viewed track is playing. This causes $refs.player to be destroyed
          if (this.$refs.player) {
            this.$refs.player.addEventListener("error", (err) => {
              console.error(err);
            });
          }
        }, 0);
      } catch (err) {
        if (err.response?.status === 503) {
          this.isNotReady = true;
        } else {
          this.isError = true;

          throw err;
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
