import './scss/styles.scss';
import { createApp } from 'vue/dist/vue.esm-bundler';
import { createRouter, createWebHistory } from 'vue-router';
import Notifications from 'notiwind';
import { config } from '@stemplayer-js/stemplayer-js/stemplayer.js';
import App from './App.vue';
import { createHead, useSeoMeta } from '@vueuse/head';
import mitt from 'mitt';
import { Amplify } from 'aws-amplify';
import { amplify } from '../config';
import AmplifyVue from '@aws-amplify/ui-vue';
import VueSocialSharing from 'vue-social-sharing';
import Home from './components/Home';
import AdminCreateTrackSelectMode from './components/AdminCreateTrack/SelectMode';
import AdminCreateTrackIndex from './components/AdminCreateTrack/Index';
import AdminCreateTrackUpload from './components/AdminCreateTrack/Upload';
import AdminEditAccount from './components/AdminEditAccount';
import AdminEditTrack from './components/AdminEditTrack';
import ViewTrackIndexPrivate from './components/ViewTrack/IndexPrivate';
import ViewTrackIndexPublic from './components/ViewTrack/IndexPublic';
import ViewTrackView from './components/ViewTrack/View';
import ViewTrackPlay from './components/ViewTrack/Play';
import ViewTrackMix from './components/ViewTrack/Mix';
import AdminListTracksSearch from './components/AdminListTracks/Search';
import AdminListTracksLocalSearch from './components/AdminListTracks/Search/Local';
import AdminListTracksOnlineSearch from './components/AdminListTracks/Search/Online';
import AdminListTracksIndex from './components/AdminListTracks/Index';
import AdminListTracksNewUploads from './components/AdminListTracks/NewUploads';
import TermsAndConditions from './components/TermsAndConditions';
import Docs from './components/Docs';
import PrivacyPolicy from './components/PrivacyPolicy';
import Contact from './components/Contact';
import SignIn from './components/SignIn';
import Signup from './components/Signup';
import DownloadMix from './components/DownloadMix';
import DownloadMaster from './components/DownloadMaster';
import OauthCallback from './components/OauthCallback';
import UserIndex from './components/UserIndex';
import StemPlayerPlugin from './plugins/stemplayer';
import CreateTrackManagerPlugin from './plugins/createTrackManager';
import AclPlugin from './plugins/acl';
import isUserId from './libs/isUserId';
import getUserProfile from './services/getUserProfile';
import PageNotFound from './components/PageNotFound';
// import { gradient, progressGradient } from "./libs/gradients";
// import { MixthatPlayer } from "@soundws/mixthat-player";

config.responsiveBreakpoints = {
  xs: '600',
  sm: '800',
};

// config.defaults.waveform = {
//   waveColor: gradient,
//   progressColor: progressGradient,
// };

const emitter = mitt();

// window.customElements.define("mixthat-player", MixthatPlayer);

Amplify.configure(amplify);

const router = createRouter({
  history: createWebHistory(),
  // linkActiveClass: "active",
  // linkExactActiveClass: "exact-active",
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter: () => {
        useSeoMeta({
          title:
            'Upload your stems and play them using our Free, Online, Virtual, Stem-Player',
          description:
            'Upload your stems then play and mix them right in the browser using our online streaming stem player',
        });
      },
    },
    {
      path: '/private/tracks/:trackuuid/edit',
      name: 'adminEditTrack',
      component: AdminEditTrack,
      beforeEnter: () => {
        useSeoMeta({
          title: 'Edit song',
          description: 'You are editing one of your songs',
        });
      },
    },
    {
      path: '/play',
      name: 'playTrackLegacy',
      beforeEnter(to, from, next) {
        next(`/tracks/${to.query.trackuuid}`);
      },
    },
    {
      path: '/admin/play',
      name: 'adminPlayTrackLegacy',
      beforeEnter(to, from, next) {
        next(`/admin/tracks/${to.query.trackuuid}`);
      },
    },
    {
      path: '/tracks/:trackuuid',
      name: 'publicTrack',
      component: ViewTrackIndexPublic,
      redirect: { name: 'publicViewTrack' },
      props: (route) => ({
        isDownloadDisabled: route.name !== 'publicMixTrack',
        trackuuid: route.params.trackuuid,
        authToken: route.query.authToken,
      }),
      children: [
        {
          path: 'view',
          name: 'publicViewTrack',
          component: ViewTrackView,
          props: (route) => ({
            trackuuid: route.params.trackuuid,
            authToken: route.query.authToken,
          }),
        },
        {
          path: 'play',
          name: 'publicPlayTrack',
          component: ViewTrackPlay,
          props: (route) => ({
            trackuuid: route.params.trackuuid,
          }),
        },
        {
          path: 'mix',
          name: 'publicMixTrack',
          component: ViewTrackMix,
          props: (route) => ({
            trackuuid: route.params.trackuuid,
            authToken: route.query.authToken,
          }),
        },
      ],
    },
    {
      path: '/private/tracks/:trackuuid',
      name: 'adminTrack',
      component: ViewTrackIndexPrivate,
      redirect: { name: 'adminViewTrack' },
      props: (route) => ({
        isDownloadDisabled: route.name !== 'adminMixTrack',
        trackuuid: route.params.trackuuid,
      }),
      children: [
        {
          path: 'view',
          name: 'adminViewTrack',
          component: ViewTrackView,
          props: (route) => ({
            trackuuid: route.params.trackuuid,
            isAdmin: true,
          }),
        },
        {
          path: 'play',
          name: 'adminPlayTrack',
          component: ViewTrackPlay,
          props: (route) => ({
            trackuuid: route.params.trackuuid,
            isAdmin: true,
          }),
        },
        {
          path: 'mix',
          name: 'adminMixTrack',
          component: ViewTrackMix,
          props: (route) => ({
            trackuuid: route.params.trackuuid,
            isAdmin: true,
          }),
        },
      ],
    },
    {
      path: '/tracks/',
      name: 'adminListTracksIndex',
      component: AdminListTracksIndex,
      meta: { title: 'Your Tracks' },
      beforeEnter: () => {
        useSeoMeta({
          title: 'My tracks',
          description: 'The track you have uploaded to Mix That!',
        });
      },
      children: [
        {
          path: 'search',
          name: 'adminListTracks',
          component: AdminListTracksSearch,
          // beforeEnter: () => {
          // },
          // redirect: "/tracks/search/local",
          children: [
            {
              path: 'local',
              name: 'adminListTracksLocalSearch',
              component: AdminListTracksLocalSearch,
            },
            {
              path: 'online',
              name: 'adminListTracksOnlineSearch',
              component: AdminListTracksOnlineSearch,
            },
          ],
        },
        {
          path: 'uploading',
          name: 'adminListTracksNewUploads',
          component: AdminListTracksNewUploads,
        },
        {
          path: 'upload',
          name: 'adminCreateTrack',
          component: AdminCreateTrackIndex,
          redirect: '/tracks/upload/select',
          // redirect: "/tracks/upload/stems",
          children: [
            {
              path: 'select',
              name: 'adminCreateTrackSelect',
              component: AdminCreateTrackSelectMode,
            },
            {
              path: 'separate-source-audio',
              name: 'AdminCreateTrackSplitOriginal',
              component: AdminCreateTrackUpload,
              props: {
                mode: 'split',
                title: 'Separate source audio',
                maxUploads: 5,
              },
            },
            {
              path: 'stems',
              name: 'AdminCreateTrackWithStems',
              component: AdminCreateTrackUpload,
              props: {
                mode: 'stem',
                title: 'Upload Stems',
                maxUploads: 20,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/terms-and-conditions',
      name: 'termsAndConditions',
      component: TermsAndConditions,
      beforeEnter: () => {
        useSeoMeta({
          title: 'Our terms and conditions',
          description: 'The terms and conditions of Mix That!',
        });
      },
    },
    {
      path: '/privacy-policy',
      name: 'privacyPolicy',
      component: PrivacyPolicy,
      beforeEnter: () => {
        useSeoMeta({
          title: 'Our Privacy Policy',
          description: 'The privacy policy of Mix That!',
        });
      },
    },
    {
      path: '/account',
      name: 'adminEditAccount',
      component: AdminEditAccount,
      beforeEnter: () => {
        useSeoMeta({
          title: 'Edit your account',
          description: 'You are editing your account',
        });
      },
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
      beforeEnter: () => {
        useSeoMeta({
          title: 'Contact Us',
          description: 'Contact Mix That!',
        });
      },
    },
    {
      path: '/signin',
      name: 'signin',
      component: SignIn,
      beforeEnter: () => {
        useSeoMeta({
          title: 'Sign In',
          description: 'Sign in to Mix That!',
        });
      },
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      beforeEnter: () => {
        useSeoMeta({
          title: 'Sign Up',
          description: 'Sign up to Mix That!',
        });
      },
    },
    {
      path: '/download-mix',
      name: 'downloadMix',
      component: DownloadMix,
      beforeEnter: () => {
        useSeoMeta({
          title: 'Download Mix',
          description: 'A mix of the stem tracks is being generated',
        });
      },
    },
    {
      path: '/download-master',
      name: 'downloadMaster',
      component: DownloadMaster,
      beforeEnter: () => {
        useSeoMeta({
          title: 'Download Master',
          description: 'The master audio track is being downloaded',
        });
      },
    },
    {
      path: '/docs',
      name: 'docs',
      component: Docs,
      beforeEnter: () => {
        useSeoMeta({
          title: 'Documentation',
          description: 'The docs',
        });
      },
    },
    {
      path: '/for-music-businesses',
      name: 'forBusiness',
      component: Docs,
      beforeEnter: () => {
        useSeoMeta({
          title: 'For Music Business',
          description: 'Stem Player for Music Businesses',
        });
      },
    },
    {
      path: '/oauth-callback',
      name: 'oauthCallback',
      component: OauthCallback,
      beforeEnter: () => {
        useSeoMeta({
          title: 'Authentication Callback',
        });
      },
    },
    {
      path: '/@:userKey',
      name: 'userIndex',
      component: UserIndex,
      props: true,
      beforeEnter: ({ params }) => {
        if (isUserId(params.userKey)) {
          return getUserProfile(params.userKey).then((profile) => {
            if (profile?.username) return { path: `/@${profile.username}` };
          });
        }

        useSeoMeta({
          title: `Works by @${params.userKey}`,
          ogTitle: `Works by @${params.userKey}`,
          description: `Find and play songs by @${
            params.userKey || 'Unknown'
          } using our stem player`,
          ogDescription: `Find and play songs by @${
            params.userKey || 'Unknown'
          } using our stem player`,
        });
      },
    },
    {
      name: 'notFound',
      path: '/404-Not-Found',
      component: PageNotFound,
      beforeEnter: () => {
        useSeoMeta({
          title: '404 Not Found',
          description: 'The Page could not be found',
        });
      },
    },
    {
      name: '404',
      path: '/:catchAll(.*)*',
      component: PageNotFound,
      beforeEnter: () => {
        return {
          path: `/404-Not-Found`,
        };
      },
    },
  ],
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

const app = createApp(App);
const head = createHead();

app.config.globalProperties.emitter = emitter;

app.use(Notifications);

// app.config.compilerOptions.isCustomElement = (tag) =>
//   tag.startsWith("soundws-");

app.use(AmplifyVue);

app.use(VueSocialSharing);

app.use(StemPlayerPlugin);

app.use(CreateTrackManagerPlugin);

app.use(AclPlugin);

app.use(head);

// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router);

app.mount('#app');
