import adminGetAccount from '@/services/adminGetAccount';
import { reactive } from 'vue';

class ACL {
  async refresh(cache = true) {
    if (this.refreshing) return;

    this.refreshing = true;
    try {
      this.account = await adminGetAccount({ cache });
    } catch (err) {
      // err can be a string "No current User"
      if (err instanceof Error) throw err;
    } finally {
      this.refreshing = false;
    }
  }

  can(acl) {
    return this.account?.[acl] === true;
  }
}

export default {
  install(app) {
    const acl = reactive(new ACL());
    acl.refresh({ cache: false });

    app.config.globalProperties.$acl = acl;
    app.provide('acl', app.config.globalProperties.$acl);
  },
};
