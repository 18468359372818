<template>
  <div class="min-vh-almost-100 d-flex align-items-center">
    <div class="w-100">
      <div class="container">
        <auth>
          <h1>Upload, Mix, Share, Embed, Play!</h1>
          <p class="lead mb-5">
            Upload stem audio files (drums, vocals, piano) below and create an
            embeddable, shareable stem player.
          </p>
        </auth>
      </div>
    </div>
  </div>
</template>

<script>
import { Hub } from 'aws-amplify';
import Auth from './Auth.vue';

export default {
  name: 'SignIn',
  components: { Auth },
  mounted() {
    const onSignin = () => {
      // if (this.$route.query.return) location.replace(this.$route.query.return);
      this.$router.push({ name: 'adminListTracks' });
      Hub.remove('auth', onSignin);
    };

    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn') onSignin();
    });
  },
};
</script>

<style scoped></style>
