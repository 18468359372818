<template>
  <div>
    <textarea
      :disabled="disabled"
      type="text"
      class="form-control text-start mb-0 rounded-bottom-0 font-code"
      :value="value"
      ref="input"
      :rows="rows"
    />
    <button
      :disabled="disabled"
      class="btn btn-light btn-sm rounded-top-0 w-100"
      type="button"
      @click="copyToClipboard"
    >
      Copy to clipboard
    </button>
  </div>
</template>

<script>
export default {
  name: 'CopyToClipboard',
  props: {
    value: {
      type: String,
    },
    rows: {
      type: Number,
      default: 2,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    copyToClipboard() {
      const { input } = this.$refs;
      input.select();
      input.setSelectionRange(0, 99999); /* For mobile devices */
      navigator.clipboard.writeText(input.value);
      this.$emit('click', this.modelValue);
    },
  },
};
</script>

<style scoped>
textarea {
  font-size: 0.75em;
}
</style>
