<template>
  <div v-if="isLoading">
    <div class="min-vh-50 d-flex align-items-center">
      <div class="w-100 margin-top-min-navbar">
        <loader></loader>
      </div>
    </div>
  </div>
  <div v-else>
    <div
      class="container container-narrow text-start mb-4 min-vh-50 d-flex align-items-center"
    >
      <div class="w-100">
        <h1>
          Tracks by
          <span class="color-brand">{{ userProfile.username }}</span>
        </h1>
        <p class="lead">{{ userProfile.bio }}</p>
        <user-links :data="userProfile"></user-links>
      </div>
    </div>

    <div class="container container text-start">
      <div class="row">
        <user-track-list
          :userKey="userKey"
          :showDescription="true"
          :showStats="true"
          :showLoadMoreButton="true"
        >
          <div class="w-100 text-center">
            <loader></loader>
          </div>
        </user-track-list>
      </div>
    </div>
  </div>
</template>

<script>
import getUserProfile from "../services/getUserProfile";
import Loader from "./Loader.vue";
import UserTrackList from "./UserTrackList.vue";
import UserLinks from "./UserLinks.vue";

export default {
  name: "UserIndex",
  components: { UserTrackList, UserLinks, Loader },
  props: {
    userKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userProfile: undefined,
      isLoading: true,
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await getUserProfile(to.params.userKey);

      // next((vm) => vm.setData(data));
    } catch (err) {
      next({ name: "notFound" });
    }

    next();
  },
  async beforeMount() {
    try {
      this.isLoading = true;
      this.userProfile = await getUserProfile(this.userKey);
    } catch (err) {
      if (err.response?.status !== 404) {
        console.error("Failed to get owner information", { err });
      }
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped></style>
