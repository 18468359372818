import { Analytics, AWSKinesisFirehoseProvider } from "aws-amplify";
import { amplify, analytics } from "../../config";
import {
  PLAYER_PLAY,
  PLAYER_PAUSE,
  PLAYER_SEEK,
  PLAYER_ERROR,
  PLAYER_END,
  PLAYER_LOADING_START,
  PLAYER_LOADING_END,
  PLAYER_TIMEUPDATE,
} from "../consts/analytics";

let defaults = {};

Analytics.addPluggable(new AWSKinesisFirehoseProvider());

Analytics.configure(amplify.AWSKinesisFirehose);

const record = (event, data) => {
  Analytics.record(
    {
      data: {
        event,
        timestamp: Date.now(),
        data: {
          ...defaults,
          ...data,
        },
      },
      streamName: analytics.streamName,
    },
    "AWSKinesisFirehose"
  );
};

const recordPlayerEvents = (el) => {
  el.addEventListener("play", () =>
    record(PLAYER_PLAY, { playerId: el.id, t: el.controller.currentTime })
  );
  el.addEventListener("pause", () =>
    record(PLAYER_PAUSE, { playerId: el.id, t: el.controller.currentTime })
  );
  el.addEventListener("seek", ({ detail }) =>
    record(PLAYER_SEEK, { playerId: el.id, ...detail })
  );
  el.addEventListener("error", (error) => {
    record(PLAYER_ERROR, { playerId: el.id, error: error.message });
  });
  el.addEventListener("end", () => record(PLAYER_END));
  el.addEventListener("loading-start", () =>
    record(PLAYER_LOADING_START, {
      playerId: el.id,
      t: el.controller.currentTime,
    })
  );
  el.addEventListener("loading-end", () =>
    record(PLAYER_LOADING_END, {
      playerId: el.id,
      t: el.controller.currentTime,
    })
  );

  // send if we're playing, send the fact that we're playing
  let tTimeupdate;
  el.addEventListener("timeupdate", ({ detail }) => {
    if (!tTimeupdate)
      tTimeupdate = setTimeout(() => {
        record(PLAYER_TIMEUPDATE, { playerId: el.id, ...detail });
        tTimeupdate = null;
      }, 2500); // max every 2.5 secs
  });
};

const setDefault = (key, value) => {
  if (value) defaults[key] = value;
  else delete defaults[key];
};

const getDefault = (key) => {
  return defaults[key];
};

export { record, recordPlayerEvents, setDefault, getDefault };
