import { API } from 'aws-amplify';

export default (trackuuid, track) =>
  API.put('admin', `/admin/tracks/${trackuuid}`, {
    body: {
      songTitle: track.songTitle,
      ['split:model']: track['split:model'],
      ['split:retry']: track['split:retry'],
      isPublished: track.isPublished,
      description: track.description,
      audio: track.audio
        ? {
            filename: track.audio.filename,
            uploadedKey: track.audio.key || track.audio.uploadedKey,
          }
        : undefined,
      stems: track.stems
        ? track.stems.map((stem) => ({
            filename: stem.filename,
            label: stem.label,
            index: stem.index,
            uploadedKey: stem.key || stem.uploadedKey,
            uploaduuid: stem.uploaduuid,
          }))
        : undefined,
    },
  });
