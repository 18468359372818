import { API } from 'aws-amplify';

export default async (trackuuid, payload = {}) => {
  const { token } = await API.post(
    'admin',
    `/admin/tracks/${trackuuid}/token`,
    { body: payload },
  );
  return token;
};
