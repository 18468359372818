<template>
  <section class="min-vh-almost-100 d-flex align-items-center text-start">
    <div class="container container-narrow">
      <form
        autocomplete="off"
        id="contact-form"
        class="contact-form"
        @submit.prevent="onSubmit"
      >
        <div class="card bg-dark-4 text-light">
          <div class="card-header text-secondary mb-0 text-end border-0">
            <h5 class="mb-0 scroll-m-top-4" ref="general">Contact</h5>
          </div>
          <div class="card-body">
            <input type="hidden" name="formName" value="Contact" />
            <div class="form-group mb-4">
              <label class="required" for="contactFormEmail">Email</label>
              <input
                name="email"
                type="email"
                class="form-control"
                id="contactFormEmail"
                aria-describedby="emailHelp"
                required
                :disabled="isSubmitting"
              />
            </div>
            <div class="form-group mb-4">
              <label class="required" for="contactFormMessage">Message</label>
              <textarea
                class="form-control"
                id="contactFormMessage"
                rows="3"
                name="message"
                required
                :disabled="isSubmitting"
              ></textarea>
            </div>
            <div>
              <button
                class="btn btn-secondary w-100"
                type="submit"
                v-show="!isSubmitting"
              >
                Send
              </button>
              <button
                class="btn btn-secondary w-100"
                type="button"
                disabled
                v-show="isSubmitting"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            </div>
          </div>
        </div>
        <div class="text-center">
          <small class="text-muted">
            By submitting this form you confirm that you agree with our
            <a href="/terms-and-conditions">terms and conditions</a>
            and <a href="/privacy-policy">our privacy policy</a>.
          </small>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Contact',
  methods: {
    async onSubmit(e) {
      this.isSubmitting = true;

      const data = new URLSearchParams(new FormData(e.target));

      try {
        await fetch('https://stemplayer-js.com/api/contact/', {
          method: 'post',
          body: data,
          mode: 'no-cors',
        });
      } catch (err) {
        console.error('An error occured', err);
      } finally {
        this.isSubmitting = false;

        e.target.reset();

        this.$notify(
          {
            title: 'Thank you',
            text: 'We have received your message. We will be in touch shortly',
            type: 'info',
            group: 'default',
          },
          4000,
        );
      }
    },
  },
  data() {
    return {
      isSubmitting: this.isSubmitting,
    };
  },
};
</script>

<style scoped></style>
