<template>
  <span v-if="trackstats">
    <span
      class="m-1 badge rounded-pill bg-dark text-light d-inline-block w-25p"
      title="The number of plays"
      ><i class="bi bi-play-fill me-1"></i>
      {{ trackstats.PLAY_MIX.total || 0 }}</span
    >
    <span
      class="m-1 badge rounded-pill bg-dark text-light d-inline-block w-25p"
      title="The number of downloads"
      ><i class="bi bi-cloud-arrow-down-fill"></i>
      {{ trackstats.DOWNLOAD_MIX.total || 0 }}</span
    >
  </span>
</template>

<script>
import getTrackStats from "../services/getTrackstats";

export default {
  name: "ActivityStats",
  props: {
    trackuuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      trackstats: undefined,
    };
  },
  async beforeMount() {
    try {
      const { data } = await getTrackStats(this.trackuuid);
      this.trackstats = data.events;
    } catch (error) {
      // do nothing
    }
  },
};
</script>

<style scoped>
.w-25p {
  min-width: 60px;
}
</style>
