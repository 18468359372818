<template>
  <div :hidden="!player.trackuuid">
    <track-options-modal
      v-if="showOptions && track"
      :track="track"
      :isAdmin="player.isAdmin"
      @close="showOptions = false"
    ></track-options-modal>
    <div
      class="wrapper"
      ref="stemPlayerPanel"
      :class="{
        'position-absolute': shouldRenderInTarget,
        'position-fixed fixed-bottom-collapsed': !shouldRenderInTarget,
      }"
    >
      <div class="float-end bg-dark-gray">
        <button
          class="btn btn-dark btn-size-player bg-dark-gray border-black border-start-0 rounded-0"
          title="Show details"
          v-if="!shouldRenderInTarget"
          @click.stop="showOptions = !showOptions"
        >
          <span class="visually-hidden">Details</span
          ><i class="bi bi-three-dots-vertical"></i>
        </button>
      </div>
      <div class="card bg-dark-4 rounded-0-fixed-bottom">
        <div class="card-body px-0 py-3 py-0-fixed-bottom">
          <stem-player
            v-if="player.trackuuid"
            :trackuuid="player.trackuuid"
            :autoplay="player.autoplay"
            :isAdmin="player.isAdmin"
            :authToken="player.authToken"
            ref="stemplayer"
            @load="onStemPlayerLoad"
          ></stem-player>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StemPlayer from "./StemPlayer";
import TrackOptionsModal from "./TrackOptionsModal.vue";

export default {
  name: "StemPlayerRenderer",
  components: {
    StemPlayer,
    TrackOptionsModal,
  },
  props: {
    player: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shouldRenderInTarget: false,
      showOptions: false,
      track: undefined,
    };
  },
  beforeMount() {
    this.emitter.on("STEMPLAYER_TARGET_MOUNTED", () => {
      this.shouldRenderInTarget = true;
    });
  },
  mounted() {
    this.shouldRenderInTarget = !!document.getElementById("stem-player-target");
    this.addListeners();
    this.render();
  },
  watch: {
    $route() {
      this.shouldRenderInTarget = false;
    },
    shouldRenderInTarget() {
      this.render();
    },
  },
  methods: {
    render() {
      const player = this.$refs.stemPlayerPanel;
      const target = document.getElementById("stem-player-target");
      if (player && !this.shouldRenderInTarget) {
        this.renderFixed();
      } else if (target && player) {
        this.renderInTarget();
      }
    },
    renderInTarget() {
      requestAnimationFrame(() => {
        const target = document.getElementById("stem-player-target");
        const rect = target.getBoundingClientRect();
        const player = this.$refs.stemPlayerPanel;
        player.style.top = `${target.offsetTop}px`;
        player.style.left = `${rect.left}px`;
        player.style.width = `${rect.width}px`;
        player.style["margin-top"] = "0px";
        target.style.height = `${player.clientHeight}px`;
      });
    },
    renderFixed() {
      requestAnimationFrame(() => {
        const player = this.$refs.stemPlayerPanel;
        if (player) {
          player.style.position = "fixed!important";
          player.style["z-index"] = "9999";
          player.style.top = "100%";
          player.style["margin-top"] = "-74px";
          player.style.left = `0px`;
          player.style.width = `100%`;
        }
      });
    },
    addListeners() {
      const el = this.$refs.stemPlayerPanel;
      const resizeObserver = new ResizeObserver(() => this.render());
      resizeObserver.observe(el);
      window.addEventListener("resize", this.render, true);
    },
    removeListeners() {
      window.removeEventListener("resize", this.render, true);
    },
    onStemPlayerLoad(track) {
      this.track = track;
    },
  },
};
</script>

<style scoped>
.fixed-bottom-collapsed {
  margin-top: -74px;
}
.fixed-bottom-uncollapsed {
  bottom: -40px;
}
.btn-size-player {
  height: 74px;
  width: 60px;
  padding: 0;
  line-height: 74px;
}
.hide {
  visibility: hidden;
}

.fixed-bottom-collapsed .rounded-0-fixed-bottom {
  border-radius: 0 !important;
}
.fixed-bottom-collapsed .py-1-fixed-bottom {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.fixed-bottom-collapsed .py-0-fixed-bottom {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.fixed-bottom-collapsed .rounded-0-fixed-bottom {
  border-radius: none !important;
}
</style>
