<template>
  <span>
    <i class="bi bi-soundwave text-primary me-1"></i>
    <span class="font-2">Mix That!</span>
  </span>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style scoped></style>
