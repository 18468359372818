<template>
  <stem-player-target
    :trackuuid="trackuuid"
    :autoplay="autoplay"
    :isAdmin="isAdmin"
  ></stem-player-target>
</template>

<script>
import StemPlayerTarget from "../StemPlayerTarget.vue";

export default {
  name: "ViewTrackPlay",
  components: { StemPlayerTarget },
  props: {
    trackuuid: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
