<template>
  <div>
    <slot v-if="isLoading"></slot>
    <div v-else class="text-start">
      <div class="header text-uppercase">
        <h5 class="mb-0" v-if="data">
          By
          <router-link
            class="color-brand"
            :to="{
              name: 'userIndex',
              params: { userKey: data.username || userKey },
            }"
            >@{{ data.username || "Unknown" }}</router-link
          >
        </h5>
      </div>
      <div class="body">{{ data.bio }}</div>
      <div class="footer text-small text-muted">
        <small class="text-muted mb-2 d-block">
          <user-links :data="data"></user-links>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import getUserProfile from "../services/getUserProfile";
import UserLinks from "./UserLinks.vue";
// import activityStats from "./ActivityStats.vue";

export default {
  name: "UserInfo",
  // components: { activityStats },
  props: {
    // username is an alias of userKey
    userKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: undefined,
      isLoading: false,
    };
  },
  async beforeMount() {
    try {
      this.isLoading = true;
      this.data = await getUserProfile(this.userKey);
    } catch (err) {
      if (err.response?.status !== 404) {
        console.error("Failed to get owner information", { err });
      }
    } finally {
      this.isLoading = false;
    }
  },
  components: { UserLinks },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
