<template>
  <modal v-slot="scope">
    <div class="min-vh-75 d-flex align-items-center py-1 text-center">
      <div class="w-100">
        <div class="bg-dark-4 list-group py-3 mb-4">
          <button
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item d-flex justify-content-between align-items-center"
            @click="downloadMix('wav', scope)"
            :disabled="!$acl.can('acl:track:downloadMix')"
          >
            Lossless / High Quality Mix
            <span
              class="badge bg-secondary rounded-pill"
              v-if="!$acl.can('acl:track:downloadMix')"
              >This feature is disabled for this track</span
            >
          </button>
          <!-- <button
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item d-flex justify-content-between align-items-center"
            @click="downloadMix('mp3', scope)"
          >
            Low Quality Mix
            <span class="badge bg-secondary rounded-pill">MP3</span>
          </button> -->
        </div>
        <!-- <div class="bg-dark-4 list-group py-3 mb-4">
          <button
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item d-flex justify-content-between align-items-center"
            @click="downloadOriginal('wav', scope)"
          >
            Lossless / High Quality Original
            <span class="badge bg-secondary rounded-pill">WAV</span>
          </button>
          <button
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item d-flex justify-content-between align-items-center"
            @click="downloadOriginal('mp3', scope)"
          >
            Low Quality Original
            <span class="badge bg-secondary rounded-pill">MP3</span>
          </button>
        </div> -->
        <!-- <div class="bg-dark-4 list-group py-3">
          <button
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item d-flex justify-content-between align-items-center"
            disabled
          >
            Lossless / High Quality Stems
            <span class="badge bg-secondary rounded-pill">WAV</span>
          </button>
          <button
            class="list-group-item list-group-item-action list-group-item-dark text-truncate py-3 px-3 list-group-item d-flex justify-content-between align-items-center"
            disabled
          >
            Low Quality Stems
            <span class="badge bg-secondary rounded-pill">MP3</span>
          </button>
        </div> -->
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal.vue';

export default {
  name: 'DownloadModal',
  components: { Modal },
  props: {
    track: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async downloadMix(format, scope) {
      try {
        const el = document.querySelector('soundws-stem-player');
        let transportState;

        if (el && el.dataset?.trackuuid === this.track.trackuuid) {
          // when downloading the current player, volumes can be modified
          const { state } = el;

          // remove unecessary props from state
          transportState = {
            format,
            stems: state.stems.map(({ id, volume }) => ({
              id,
              volume,
            })),
          };
        } else {
          // when downloading when the track to which the download applies is not actually currently playing in a stem player: volumes will be 1
          transportState = {
            format,
            stems: this.track.stems.map((stem) => ({
              id: stem.uploaduuid,
              volume: 1,
            })),
          };
        }

        const route = this.$router.resolve({
          name: 'downloadMix',
          query: {
            trackuuid: this.track.trackuuid,
            isAdmin: this.isAdmin,
            state: btoa(JSON.stringify(transportState)),
          },
        });

        window.open(route.href, '_blank');
      } catch (error) {
        this.$notify(
          {
            title: 'Error',
            text: 'An error occured',
            type: 'error',
            group: 'default',
          },
          4000,
        );
      } finally {
        scope.close();
      }
    },
    // downloadOriginal(format, scope) {
    //   console.log(format);
    //   scope.close();
    // },

    // async onDownloadMasterClick() {
    //   const route = this.$router.resolve({
    //     name: "downloadMaster",
    //     query: { trackuuid: this.track.trackuuid },
    //   });

    //   window.open(route.href, "_blank");
    // },
  },
};
</script>

<style scoped></style>
