<template>
  <div class="bg-waves d-flex align-items-center pb-1">
    <div class="container container-narrow container-narrow-lg mb-4">
      <div class="card p-4 text-start bg-dark-4 text-light">
        <div class="card-header text-secondary mb-0 text-end border-0">
          <h5 class="mb-0 scroll-m-top-4">Terms and Conditions</h5>
          <p>Last Updated: <date>23 Aug 2022</date></p>
        </div>
        <div class="card-body">
          <article>
            <h2>Definitions</h2>
            <ul>
              <li>
                &quot;We&quot;, &quot;Us&quot; and &quot;Ours&quot; mean Sound
                Web Services LTD.
              </li>
              <li>
                &quot;The Websites&quot; means the website available under the
                domain mixthat.co.
              </li>
              <li>
                &quot;The Software&quot; means the Stem Player Web Component
              </li>
              <li>
                &quot;The Documentation&quot; means any documentation which we
                may make available for The Products either by publishing it on
                The Website or shared with you by any other means.
              </li>
              <li>
                &quot;The Products&quot; means The Websites and/or the Software
                and/or The Documentation.
              </li>
              <li>
                &quot;Intellectual property rights&quot; mean, but are not
                limited to, copyright, trademarks, design, patent rights, trade
                secrets, moral rights, know-how, and any other Intellectual
                property rights (including applications and disclosures)
                recognized in any country or jurisdiction worldwide.
              </li>
            </ul>
            <h2>Conditions of Use</h2>
            <p>
              The access and use by you of The Products is subject to these
              Terms and Conditions. By using the The Products you are deemed to
              have agreed to all the terms, conditions and disclaimers contained
              in this notice and you will not use the The Products in any way
              that contravenes these Terms and Conditions. If you do not agree
              to these Terms and Conditions in full then you are not permitted
              to access or otherwise use the The Products.
            </p>
            <h2>Modifications to the Products</h2>
            <p>
              We reserve the right to update or amend these Terms and Conditions
              at any time and your continued use of the Products following any
              such changes shall be treated as acceptance by you of such
              changes. An indication that changes have been made to these Terms
              and Conditions will be identifiable by a change in the date at the
              top of this page. We reserve the right for any reason in its sole
              discretion to terminate, change, suspend or discontinue any aspect
              of the Products.
            </p>
            <p></p>
            <h2>Acceptable Use</h2>
            <p>
              The products are intended to allow users to upload, administer and
              share content.
            </p>
            <p>
              Subject to the terms contained in this agreement, we grant you a
              revocable, limited licence to use the Products for the purposes
              for which the Product is intended.
            </p>

            <h3>You must not</h3>
            <ul>
              <li>
                use the Products in any way which infringes the copyright,
                trademark, patent or other proprietary right of any party;
              </li>
              <li>
                use the Products to engage in activities which are unethical or
                immoral or illegal;
              </li>
              <li>
                alter, edit or adapt, decompile, de-obfuscate or reverse
                engineer, or attempt to decompile, de-obfuscate or reverse
                engineer the Products.
              </li>
              <li>use the Product to make a derivative product;</li>
              <li>
                sell, resell, rent, lease, loan, sublicense, supply, publish,
                distribute or redistribute the Product;
              </li>
              <li>
                damage or impair or attempt to damage or impair the
                functionality of the Product (including, but not limited to, by
                the use of automated scripts, bots, &quot;hacking&quot;,
                injecting of harmful code, exploits);
              </li>
            </ul>
            <h3>You must</h3>
            <ul>
              <li>
                at all times use the Products in accordance with the purpose
                prescribed by Us either in These terms and/or the Documentation
                and/or by any other means;
              </li>
              <li>
                report to us conduct which You consider to be in violation of
                the terms of the Products via
                <a class="text-secondary" href="mailto:support@sound.ws"
                  >support@sound.ws</a
                >
              </li>
            </ul>
            <h2>Term</h2>
            <p>
              The Term will commence on the date you accept these Terms and
              Conditions, or when you start using the Products and will remain
              in force until you stop using the Products or terminate your plan,
              or when we terminate this agreement.
            </p>
            <h2>Termination</h2>
            <p>
              We shall have the right to terminate your access to the Products
              without notice at any time, for any reason, including without
              limitation the breach by you of any of these Terms and Conditions.
              You agree that We shall not be liable to you or any third party
              for any termination of your access to the Products including the
              suspension or discontinuance of the Products.
            </p>
            <h2>Reporting infringements</h2>
            <p>
              If you discover any content on the Products that you believe
              infringes your copyright, or the copyright of any third-party,
              please report this to us by contacting us at
              <router-link
                :to="{
                  name: 'contact',
                }"
                ><small>https://mixthat.co/contact</small></router-link
              >.
            </p>
            <h2>Intellectual Property Rights</h2>
            <p>
              All tangible and intangible rights, benefits, title to, interest
              and any and all Intellectual property rights, whether registerable
              or not, in the Products (excluding Your Content) are and will
              remain the exclusive property of Us, including copies,
              improvements, enhancements, derivative works and modifications
              thereof.
            </p>
            <p>
              Except for the license granted, you are not granted, neither
              expressly or implied, any ownership of or license to any
              Intellectual property rights in the Products.
            </p>
            <p>
              If you communicate to Us any suggestions for improvements, ideas,
              enhancement requests, complaints or other feedback in connection
              with the Products, We shall own all right, title, and interest in
              and to the same, even if you have designated the feedback as
              confidential, and We shall be entitled to use the feedback without
              restriction.
            </p>
            <h3>Your Content</h3>
            <p>
              All audio, comments, pictures and other content that you post or
              upload (Your Content) is owned and controlled solely by you.
            </p>
            <p>
              We do not claim ownership rights to Your Content. By posting Your
              Content your agree that that content remains your sole
              responsibility.
            </p>
            <p>
              You must not upload, make available, or continue to make available
              content to which you do not have the necessary rights. Any
              unauthorized use of copyrighted works in Your Content may infringe
              third party rights and is therefore strictly prohibited and may
              result in litigation or procecution by the rightsholder.
            </p>
            <p>
              We have no responsibility to monitor any Content which has been
              posted to the Products by any of the users.
            </p>
            <h3>Grant of License</h3>
            <p>
              You hereby grant Us a limited, worldwide, non-exclusive,
              royalty-free and fully paid license to Us to store Your Content on
              our servers, in original and/or in modified and/or transcoded
              form.
            </p>
            <p>
              You also grant a limited, worldwide, non-exclusive, royalty-free,
              fully paid up, license to other users of the Products and to
              operators and users of any other websites, apps and/or platforms
              to which Your Content has been shared or embedded using the
              Products to use share, re-share, download, listen to offline,
              transmit, mix, adapt, Your content.
            </p>
            <p>
              The licenses granted in this section are granted separately with
              respect to each item of Your Content that you upload to the
              Products. Licenses with respect to audio Content, and any images
              or text within your account, will (subject to the following
              paragraph of these Terms of Use) terminate automatically when you
              remove such Content from your account. Licenses with respect to
              comments or other contributions that you make using the Product
              will be perpetual and irrevocable, and will continue
              notwithstanding any termination of your account.
            </p>
            <p>
              When you remove Your Content from the Products, this will
              automatically result in the deletion of the relevant files from
              our servers. However, where Your Content is distributed to third
              party websites/services, by embedding or by any other means you
              acknowledge that we have no responsibility to ensure that Your
              content is deleted by these third parties.
            </p>
            <p>
              In addition, you also acknowledge that after deletion Your content
              may persist and be available through caches, backups or other
              storage mechanisms for a limited time.
            </p>
            <h2>Indemnity</h2>
            <p>
              You agree to indemnify Us from and against all costs, claims,
              demands, proceedings and damages including its own reasonable
              legal costs which are incurred by Us howsoever arising in respect
              of any breach by you of these Terms and Conditions and from and
              against any claim or demand brought against Us by any third party
              arising out of your use of the Products.
            </p>
            <h2>Disclaimers and Limitation of Liability</h2>
            <p>
              TO THE EXTENT PERMITTED BY LAW WE WILL NOT BE RESPONSIBLE OR
              LIABLE FOR ANY LOSS OR DAMAGES OF ANY NATURE WHETHER DIRECT OR
              INDIRECT INCLUDING ANY LOSS OF PROFITS OR ANY CONSEQUENTIAL
              DAMAGES SUFFERED OR INCURRED BY YOU ARISING OUT OF OR IN
              CONNECTION WITH THE USE OF THE PRODUCTS. USE BY YOU OF THE
              PRODUCTS IS AT YOUR OWN RISK. THE PRODUCTS ARE PROVIDED ON AN "AS
              IS" AND "AS AVAILABLE" BASIS WITHOUT ANY REPRESENTATION OR
              WARRANTY OF ANY KIND WHETHER EXPRESS OR IMPLIED INCLUDING BUT NOT
              LIMITED TO ANY WARRANTY AS TO CONTENT AND ACCURACY OF INFORMATION,
              USEFULNESS, TITLE, NON-INFRINGEMENT, FITNESS FOR A PARTICULAR
              PURPOSE OR THAT THE THE PRODUCTS WILL MEET YOUR REQUIREMENTS.
              FURTHER, NO WARRANTY IS GIVEN BY US THAT THE FUNCTIONALITY OF THE
              PRODUCTS WILL BE UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE
              CORRECTED OR THAT THE PRODUCTS OR THE SERVER THAT MAKES THEM
              AVAILABLE ARE FREE OF VIRUSES OR ANYTHING ELSE WHICH MAY BE
              HARMFUL OR DESTRUCTIVE. IF YOU ARE DISSATISFIED WITH THE PRODUCTS
              YOUR SOLE REMEDY IS TO CEASE USING THEM. NOTHING IN THESE TERMS
              AND CONDITIONS SHALL BE CONSTRUED SO AS TO EXCLUDE OR LIMIT THE
              LIABILITY OF US FOR DEATH OR PERSONAL INJURY AS A RESULT OF THE
              NEGLIGENCE OF US.
            </p>
            <p>
              IN ADDITION, AND TO THE MAXIMUM EXTEND PERMITTED BY LAW, WE HAVE
              NO LIABILITY WHATSOEVER TO ANY CONTENT WHICH HAS BEEN POSTED TO
              THE PRODUCTS BY USERS.
            </p>
            <h2>Data Protection</h2>
            <p>
              Where in the course of using the Products you are asked to provide
              any information our use of such information is governed by our
              <a class="text-secondary" href="/privacy-policy">Privacy Policy</a
              >, which we urge you to read.
            </p>
            <h2>Governing Law</h2>
            <p>
              These Terms and Conditions shall be construed in accordance with
              English law and you hereby submit to the exclusive jurisdiction of
              the English courts.
            </p>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions",
};
</script>
