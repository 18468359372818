import { API, Cache } from "aws-amplify";

export default async (key) => {
  const path = `/user-profiles/${key}`;

  const cached = Cache.getItem(path);
  if (cached) return cached;

  const { data } = await API.get("cdn", path);

  Cache.setItem(path, data, {
    expires: new Date(Date.now() + 3600),
  });

  return data;
};
