import { API } from "aws-amplify";

export default async (key, options = {}) => {
  const params = [
    `key=${key}`,
    options.limit ? `limit=${options.limit}` : undefined,
    options.LastEvaluatedKey
      ? `next=${encodeURIComponent(JSON.stringify(options.LastEvaluatedKey))}`
      : "",
  ].filter((e) => !!e);

  const result = await API.get("cdn", `/tracks/?${params.join("&")}`);

  return result.data;
};
